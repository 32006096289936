document.addEventListener('DOMContentLoaded', function() {
    var subscribing = false;
    $('.newsletter-subscribe').submit(function(e)
    {
        e.preventDefault();

        if(subscribing)
            return;
        subscribing = true;

        var $form = $(this);
        var button = $('.newsletter_subscribe_button');

        $form.find(".newsletter-subscribe-result").slideUp();

        button.prop('disabled',true);
        var text_val = button.val();
        var text = button.html();
        button.val(button.attr('data-alt-text'));
        button.html(button.attr('data-alt-text'));

        $.ajax(
            {
                type:'POST',
                url: $form.attr('data-action'),
                data:$form.serialize(),
                success:function(response)
                {
                    button.val(text_val);
                    button.html(text);
                    button.prop('disabled',false);
                    submitting = false;
                    $('#newsletter_subscribe_button').prop('disabled',false);

                    if(response.success){
                        $form.find(".newsletter-subscribe-result").html('<p style="color: #2f9400">'+response.message+'</p>').slideDown();
                        $('#newsletter-input').slideUp();
                    }
                    else
                        $form.find(".newsletter-subscribe-result").html('<p style="color: #ff2e2f">'+response.message+'</p>').slideDown();

                    subscribing = false;

                },
                error:function(){
                    button.val(text_val);
                    button.html(text);
                    button.prop('disabled',false);
                    submitting = false;
                    $form.find(".newsletter-subscribe-result").html('<p style="color: #ff2e2f">An error occured. Please try again.</p>').slideDown();

                    $('#newsletter_subscribe_button').prop('disabled',false);
                    subscribing = false;
                }
            });

    })
}, false);