var map,url,gpx,map_boat_position,load_articles,site_id,navigation_initialized,active_trackings,sblang; // must be instancied here for WP plugin map-init.js

function displayMapWarning(name,data){
    var html = '<div class="tracking-warning">'+sblang.tracking_offline_warning.replace('%name%',name)+'<i id="tracking-warning-close" class="fa fa-times"></i></div>';
    $(document).find('#map-wrapper').append(html);
    document.getElementById("tracking-warning-close").onclick = function() {
        this.parentNode.style.display = 'none';
    }

}
async function checkTrackingHealth(){


    if((typeof active_trackings === 'undefined' && active_trackings.length < 1) || typeof tracking_check_url === 'undefined')
        return;

    let xhr = new XMLHttpRequest();
    xhr.open('GET', tracking_check_url);
    xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
            try {
                var status = (JSON.parse(xhr.response));
            }catch(e){
                return; // Error / Timeout
            }
            if(typeof status.data === 'undefined' || status.data.length < 1)
                return;
            
            for (const [tracking_id, name] of Object.entries(active_trackings)) {
                for (const [id,data] of Object.entries(status.data)) {
                    if(data.id == tracking_id && data.status !== 1)
                        displayMapWarning(name,data);
                }
            }
        } else {
            // error
        }
    };
    xhr.onerror = function () {
        // error
    };
    xhr.send();
}

function mapLoader(timeout){
    var map_loader = $('<div>').addClass('map-loader').html('<svg version="1.1" id="map-spinner" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve"> <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"></path></svg>');

    if((window.items_loading || window.track_loading) && $(document).find('.map-loader').length === 0){
        map_loader.insertBefore($('#map,#windy'));
    }
    if(timeout < 0 || (
        (window.items_loading && window.items_loaded && window.track_loading && window.track_loaded)
        || (window.items_loading && window.items_loaded && !window.track_loading && !window.track_loaded)
        || (!window.items_loading && !window.items_loaded && window.track_loading && window.track_loaded)
    )){
        $('.map-loader').remove();
    }
    setTimeout(function () {
        mapLoader(timeout-50);
    },50);
}

function initMainMap(mapElement) {

    var zoom = parseInt(mapElement.attr('data-zoom'));
    var maxzoom = parseInt(mapElement.attr('data-provider-max-zoom'));
    var center = mapElement.attr('data-center-on');
    var lat = mapElement.attr('data-lat');
    var lng = mapElement.attr('data-lng');

    if(typeof map !== 'object')
        map = L.map('map',{
            worldCopyJump:false,
            scrollWheelZoom: (typeof map_scroll_zoom_enabled !=='undefined'), // default to false, true for MapOnly
            center:(typeof lat !=='undefined' && typeof lng !== 'undefined' ? [lat,lng] : [17,-61]),
            zoom: (zoom > 0 && zoom < 24 ? zoom : 7),
            dragging: !(L.Browser.mobile && typeof map_mobile_scroll ==='undefined'),
            tap: !(L.Browser.mobile && typeof map_mobile_scroll ==='undefined'),
            ext: mapElement.attr('data-map-ext'),
            subdomains: mapElement.attr('data-map-subdomains'),
            maxZoom: (maxzoom > 0 && maxzoom < 17 ? maxzoom : 17),
            minZoom: 3,
          //  zoomControl: ( typeof mapElement.attr('data-controls-position') !== 'undefined'),
        });
    //if( typeof mapElement.attr('data-controls-position') !== 'undefined')
    //    L.control.zoom({ position: mapElement.attr('data-controls-position') }).addTo(map);

    L.tileLayer(mapElement.attr('data-provider-url'), {
        maxZoom: parseInt(mapElement.attr('data-provider-max-zoom'))
    }).addTo(map);

    drawButtons(map,mapElement.attr('data-controls-position'));
    loadTracks(map,(center === 'boat' || center === 'track' ? center : 'boat'));
    loadMapItems(map);
    checkTrackingHealth();
    return map;
}
function initWindyMap(mapElement) {

    var zoom = parseInt(mapElement.attr('data-zoom'));
    var maxzoom = parseInt(mapElement.attr('data-provider-max-zoom'));
    var center = mapElement.attr('data-center-on');
    var lat = mapElement.attr('data-lat');
    var lng = mapElement.attr('data-lng');
    var map;
    const options = {
        worldCopyJump:false,
        // Required: API key
        key: mapElement.attr('data-key'),
        center:(lat !=='' && lng !== '' ? [lat,lng] : [17,-61]),
        verbose: windyDebug !== undefined ? windyDebug:false,
        scrollWheelZoom:false,
        zoom: (zoom > 0 && zoom < 24 ? zoom : 7),
        dragging: !(L.Browser.mobile && typeof map_mobile_scroll ==='undefined'),
        tap: !(L.Browser.mobile && typeof map_mobile_scroll ==='undefined'),
        ext: mapElement.attr('data-map-ext'),
        subdomains: mapElement.attr('data-map-subdomains'),
        maxZoom: (maxzoom > 0 && maxzoom < 24 ? maxzoom : 24),
        minZoom: 3,
    };

    if(typeof windyAPI === 'object')
        return; // Map already initialized

        $.getScript('https://api.windy.com/assets/map-forecast/libBoot.js', function(jd) {
            // Call custom function defined in script

            windyInit(options, windyAPI => {

                // windyAPI is ready, and contain 'map', 'store',broadcast
                map = windyAPI.map;
                var zoomOptions = {
                    zoomInText: '1',
                    zoomOutText: '0',
                };
                L.control.zoom(zoomOptions).addTo(windyAPI.map);

                drawButtons(windyAPI.map,mapElement.attr('data-controls-position'));
                loadTracks(windyAPI.map,(center === 'boat' || center === 'track' ? center : 'boat'));
                loadMapItems(map);
               // loadLogEntries(map);
                checkTrackingHealth();
                windyAPI.map.on('click',function (e) {
                    windyAPI.picker.open({ lat: e.latlng.lat, lon: e.latlng.lng });
                });
                if($(document).find('#map-infos').hasClass('lower-left')){
                    var w = $(document).find('#map-infos').width()+30;
                    $('#windy').find('#bottom').css({'margin-left':w});
                }

                if($(document).find('#map-infos').hasClass('lower-right')){
                    var w = $(document).find('#map-infos').width()+30;
                    $('#windy').find('#bottom').css({'margin-right':w});
                }
                $('#windy .leaflet-control-zoom .leaflet-control-zoom-in').text('+');
                $('#windy .leaflet-control-zoom .leaflet-control-zoom-out').text('-');
            });
        });

    return map;
}

function DaysInMonth(date)
{
    var monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
    var monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    var monthLength = (monthEnd - monthStart) / (1000 * 60 * 60 * 24);
    return monthLength;
}
function formatDateTZ(date,withTime=true) {
    var locale = 'en';
    try {
        locale = sblang.locale;
    }catch (e) {locale = 'en';}

    const formatter = new Intl.DateTimeFormat(locale, { month: 'short' });
    //Grab each of your components
    const month_string = formatter.format(date);
    var yyyy = date.getFullYear().toString();
    var MM = (date.getMonth()+1).toString();
    var dd  = date.getDate().toString();
    var hh = date.getHours().toString();
    var mm = date.getMinutes().toString();
    var ss = date.getSeconds().toString();
    var offset = date.getTimezoneOffset(); // if your time zone is GMT+2, -120 will be returned.
    var timezone = offset/-60;
    if(timezone > 0)
        timezone = '+'+timezone; // result UTC+2 instead of UTC2

    //Returns your formatted result
    if(withTime)
        return  (dd[1]?dd:"0"+dd[0]) + '.' + (MM[1]?MM:"0"+MM[0]) + '.' +  yyyy + ' @' + (hh[1]?hh:"0"+hh[0]) + 'h' + (mm[1]?mm:"0"+mm[0])+ ' UTC'+timezone;
    else
        return  (dd[1]?dd:"0"+dd[0]) + ' ' + month_string + ' ' +  yyyy;
}
function formatDateToMinutesAgo(date){

    var now = new Date();

    if(date > now || !(date instanceof Date))
        return 'N/A'; // Date in the future or error

    var diffMs = (now-date); // milliseconds between now & date
    var diffYears = Math.floor(diffMs / (86400000*365)); // years
    var diffMonths = Math.floor(diffMs / (86400000*30)); // Months
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


    var date_string = '';

    if(document.documentElement.lang === 'fr')
        date_string += 'il y a ';

    
    if(diffDays > 10 || diffMonths > 1 || diffYears > 1){
        date_string = formatDateTZ(date,false);
    }else{
        if(diffDays===1)
            date_string += diffDays+' '+sblang.day+', ';
        else if(diffDays>1)
            date_string += diffDays+' '+sblang.days+', ';

        if(diffHrs===1)
            date_string += diffHrs+' '+sblang.hour;
        else if(diffHrs>1)
            date_string += diffHrs+' '+sblang.hours;



        if(diffDays < 1) { // only display minutes if days is zero
            if(diffHrs > 0)
                date_string += ', ';

            date_string += diffMins + ' min.';
        }


        if(document.documentElement.lang === 'en')
            date_string += ' ago';
    }
    console.log('date_string',date_string);
    return date_string;
}
function formatDateToString(date)
{
    var now = new Date();

    var days = now.getDate() - date.getDate();
    if (days < 0)
    {
        now.setMonth(now.getMonth() - 1);
        days += DaysInMonth(now);
    }
    var months = now.getMonth() - date.getMonth();
    if (months < 0)
    {
        now.setFullYear(now.getFullYear() - 1);
        months += 12;
    }

    days = Math.ceil(days); // avoid having float

    var years = now.getFullYear() - date.getFullYear();

    var string = '';

    years = parseInt(years);
    months = parseInt(months);
    days = parseInt(days);

    if (years > 1) string += years + " "+sblang.years;
    else if(years === 1) string += years + " "+sblang.year;

    if(years > 0 && months > 0)
        string += ', '

    if (months > 1) string += months + " "+sblang.months;
    else if(months === 1) string += months + " "+sblang.month;

    if((months > 0 && days > 0) || (months===0 && years > 0 && days > 0))
        string += ' '+sblang.and+' ';

    if (days > 1) string += days + " "+sblang.days;
    else if(days === 1) string += days + " "+sblang.day;

    return string;
}
function formatDate(date) {

    var userLang = navigator.language || navigator.userLanguage;
    var formatted_date = new Date(date);
    var options = { day: 'numeric',  month: 'long', year: 'numeric' };

    return formatted_date.toLocaleDateString(userLang, options);
}
function cloneOptions (options) {
    var ret = {};
    for (var i in options) {
        var item = options[i];
        if (item && item.clone) {
            ret[i] = item.clone();
        } else if (item instanceof L.Layer) {
            ret[i] = cloneLayer(item);
        } else {
            ret[i] = item;
        }
    }
    return ret;
}

function cloneInnerLayers (layer) {
    var layers = [];
    layer.eachLayer(function (inner) {
        layers.push(cloneLayer(inner));
    });
    return layers;
}

function drawButtons(map,controlsPosition) {

    var pos = 'topleft'
    if(controlsPosition === 'upper-right')pos='topright';
    if(controlsPosition === 'upper-left')pos='topleft';
    if(controlsPosition === 'lower-right')pos='bottomright';
    if(controlsPosition === 'lower-left')pos='bottomleft';

    var centerBoat =  L.Control.extend({

        options: {
            position: pos
        },

        onAdd: function (map) {
            // leaflet-custom-btn is the class used in SB code to hide button on special pages like contacts,...
            var container = L.DomUtil.create('div','leaflet-custom-btn leaflet-control-zoom leaflet-bar leaflet-recenter leaflet-control ');
            var button = L.DomUtil.create('a','leaflet-control-custom',container);
            var icon = L.DomUtil.create('i','fa fa-crosshairs',button);
            container.title = 'Center map on boat';
            container.classList.add('center-boat');
            container.onclick = function(){
                if(typeof map_boat_position !== 'undefined')
                    map.flyTo(map_boat_position);
            };

            return container;
        }
    });
    var terminator =  L.Control.extend({

        options: {
            position: pos
        },

        onAdd: function (map) {
            // leaflet-custom-btn is the class used in SB code to hide button on special pages like contacts,...
            var container = L.DomUtil.create('div','leaflet-custom-btn leaflet-control-zoom leaflet-bar leaflet-terminator leaflet-control');
            var button = L.DomUtil.create('a','leaflet-control-custom',container);

            var terminator_layer = L.terminator();


            container.title = "Show day and night regions";
            container.classList.add('day-night-toggle');
            container.onclick = function(){

                if(map.hasLayer(terminator_layer)){
                    map.removeLayer(terminator_layer);
                    container.classList.remove('active');
                }
                else{
                    map.addLayer(terminator_layer);
                    container.classList.add('active');
                }

            };

            return container;
        }
    });


    var ruler = L.Control.Ruler = L.Control.extend({
        options: {
            position: pos,
            circleMarker: {
                color: 'red',
                radius: 2
            },
            lineStyle: {
                color: 'red',
                dashArray: '1,6'
            },
            lengthUnit: {
                display: 'NM',
                decimal: 2,
                factor: 0.5399568035, // km to Nautical miles
                label: 'Distance '
            },
            angleUnit: {
                display: '&deg;',
                decimal: 2,
                factor: null,
                label: 'Bearing   '
            }
        },
        onAdd: function(map) {
            this._map = map;

            this._container = L.DomUtil.create('div', 'leaflet-bar');
            var button = L.DomUtil.create('a','leaflet-custom-btn leaflet-control-custom',this._container);

            this._container.title = "Mesure distances";
            this._container.classList.add('ruler');

            this._container.classList.add('leaflet-ruler');
            L.DomEvent.disableClickPropagation(this._container);
            L.DomEvent.on(this._container, 'click', this._toggleMeasure, this);
            this._choice = false;
            this._defaultCursor = this._map._container.style.cursor;
            this._allLayers = L.layerGroup();
            return this._container;
        },
        onRemove: function() {
            L.DomEvent.off(this._container, 'click', this._toggleMeasure, this);
        },
        _toggleMeasure: function() {
            this._choice = !this._choice;
            this._clickedLatLong = null;
            this._clickedPoints = [];
            this._totalLength = 0;
            if (this._choice){
                this._map.doubleClickZoom.disable();
                L.DomEvent.on(this._map._container, 'keydown', this._escape, this);
                L.DomEvent.on(this._map._container, 'dblclick', this._closePath, this);
                this._container.classList.add("leaflet-ruler-clicked");
                this._clickCount = 0;
                this._tempLine = L.featureGroup().addTo(this._allLayers);
                this._tempPoint = L.featureGroup().addTo(this._allLayers);
                this._pointLayer = L.featureGroup().addTo(this._allLayers);
                this._polylineLayer = L.featureGroup().addTo(this._allLayers);
                this._allLayers.addTo(this._map);
                this._map._container.style.cursor = 'crosshair';
                this._map.on('click', this._clicked, this);
                this._map.on('mousemove', this._moving, this);
            }
            else {
                this._map.doubleClickZoom.enable();
                L.DomEvent.off(this._map._container, 'keydown', this._escape, this);
                L.DomEvent.off(this._map._container, 'dblclick', this._closePath, this);
                this._container.classList.remove("leaflet-ruler-clicked");
                this._map.removeLayer(this._allLayers);
                this._allLayers = L.layerGroup();
                this._map._container.style.cursor = this._defaultCursor;
                this._map.off('click', this._clicked, this);
                this._map.off('mousemove', this._moving, this);
            }
        },
        _clicked: function(e) {
            this._clickedLatLong = e.latlng;
            this._clickedPoints.push(this._clickedLatLong);
            L.circleMarker(this._clickedLatLong, this.options.circleMarker).addTo(this._pointLayer);
            if(this._clickCount > 0 && !e.latlng.equals(this._clickedPoints[this._clickedPoints.length - 2])){
                if (this._movingLatLong){
                    L.polyline([this._clickedPoints[this._clickCount-1], this._movingLatLong], this.options.lineStyle).addTo(this._polylineLayer);
                }
                var text;
                this._totalLength += this._result.Distance;
                if (this._clickCount > 1){
                    text = '<b>' + this.options.angleUnit.label + '</b>&nbsp;' + this._result.Bearing.toFixed(this.options.angleUnit.decimal) + '&nbsp;' + this.options.angleUnit.display + '<br><b>' + this.options.lengthUnit.label + '</b>&nbsp;' + this._totalLength.toFixed(this.options.lengthUnit.decimal) + '&nbsp;' +  this.options.lengthUnit.display;
                }
                else {
                    text = '<b>' + this.options.angleUnit.label + '</b>&nbsp;' + this._result.Bearing.toFixed(this.options.angleUnit.decimal) + '&nbsp;' + this.options.angleUnit.display + '<br><b>' + this.options.lengthUnit.label + '</b>&nbsp;' + this._result.Distance.toFixed(this.options.lengthUnit.decimal) + '&nbsp;' +  this.options.lengthUnit.display;
                }
                L.circleMarker(this._clickedLatLong, this.options.circleMarker).bindTooltip(text, {permanent: true, className: 'result-tooltip'}).addTo(this._pointLayer).openTooltip();
            }
            this._clickCount++;
        },
        _moving: function(e) {
            if (this._clickedLatLong){
                L.DomEvent.off(this._container, 'click', this._toggleMeasure, this);
                this._movingLatLong = e.latlng;
                if (this._tempLine){
                    this._map.removeLayer(this._tempLine);
                    this._map.removeLayer(this._tempPoint);
                }
                var text;
                this._addedLength = 0;
                this._tempLine = L.featureGroup();
                this._tempPoint = L.featureGroup();
                this._tempLine.addTo(this._map);
                this._tempPoint.addTo(this._map);
                this._calculateBearingAndDistance();
                this._addedLength = this._result.Distance + this._totalLength;
                L.polyline([this._clickedLatLong, this._movingLatLong], this.options.lineStyle).addTo(this._tempLine);
                if (this._clickCount > 1){
                    text = '<b>' + this.options.angleUnit.label + '</b>&nbsp;' + this._result.Bearing.toFixed(this.options.angleUnit.decimal) + '&nbsp;' + this.options.angleUnit.display + '<br><b>' + this.options.lengthUnit.label + '</b>&nbsp;' + this._addedLength.toFixed(this.options.lengthUnit.decimal) + '&nbsp;' +  this.options.lengthUnit.display + '<br><div class="plus-length">(+' + this._result.Distance.toFixed(this.options.lengthUnit.decimal) + ')</div>';
                }
                else {
                    text = '<b>' + this.options.angleUnit.label + '</b>&nbsp;' + this._result.Bearing.toFixed(this.options.angleUnit.decimal) + '&nbsp;' + this.options.angleUnit.display + '<br><b>' + this.options.lengthUnit.label + '</b>&nbsp;' + this._result.Distance.toFixed(this.options.lengthUnit.decimal) + '&nbsp;' +  this.options.lengthUnit.display;
                }
                L.circleMarker(this._movingLatLong, this.options.circleMarker).bindTooltip(text, {sticky: true, offset: L.point(0, -40) ,className: 'moving-tooltip'}).addTo(this._tempPoint).openTooltip();
            }
        },
        _escape: function(e) {
            if (e.keyCode === 27){
                if (this._clickCount > 0){
                    this._closePath();
                }
                else {
                    this._choice = true;
                    this._toggleMeasure();
                }
            }
        },
        _calculateBearingAndDistance: function() {
            var f1 = this._clickedLatLong.lat, l1 = this._clickedLatLong.lng, f2 = this._movingLatLong.lat, l2 = this._movingLatLong.lng;
            var toRadian = Math.PI / 180;
            // haversine formula
            // bearing
            var y = Math.sin((l2-l1)*toRadian) * Math.cos(f2*toRadian);
            var x = Math.cos(f1*toRadian)*Math.sin(f2*toRadian) - Math.sin(f1*toRadian)*Math.cos(f2*toRadian)*Math.cos((l2-l1)*toRadian);
            var brng = Math.atan2(y, x)*((this.options.angleUnit.factor ? this.options.angleUnit.factor/2 : 180)/Math.PI);
            brng += brng < 0 ? (this.options.angleUnit.factor ? this.options.angleUnit.factor : 360) : 0;

            // distance
            var R = this.options.lengthUnit.factor ? 6371 * this.options.lengthUnit.factor : 6371; // kilometres
            var deltaF = (f2 - f1)*toRadian;
            var deltaL = (l2 - l1)*toRadian;
            var a = Math.sin(deltaF/2) * Math.sin(deltaF/2) + Math.cos(f1*toRadian) * Math.cos(f2*toRadian) * Math.sin(deltaL/2) * Math.sin(deltaL/2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
            var distance = R * c;
            this._result = {
                Bearing: brng,
                Distance: distance
            };
        },
        _closePath: function() {
            this._map.removeLayer(this._tempLine);
            this._map.removeLayer(this._tempPoint);
            if (this._clickCount <= 1) this._map.removeLayer(this._pointLayer);
            this._choice = false;
            L.DomEvent.on(this._container, 'click', this._toggleMeasure, this);
            this._toggleMeasure();
        }
    });

    map.addControl(new centerBoat());
    map.addControl(new ruler());
    map.addControl(new terminator());

}
function exist(val) {
    return typeof val !== 'undefined' && val !== null && val !== '';
}
function loadLogEntries(map){

    if(typeof navigation_initialized !== 'undefined' && navigation_initialized === false)
        return;

    if(window.logentries_loading === true || window.logentries_loaded === true || (typeof load_logs === 'undefined')) // In map-embeeded
        return;

    window.logentries_loading = true;

    $.ajax({
        url: '/map/logs'+(typeof site_id !== 'undefined' ? '/'+site_id:''), // side_id In map-embeeded
        success:function (data) {
            data.forEach(function (item) {
               console.log(item);
            });
            window.logentries_loaded = true;
        }
    });

}
function generateItemsMarkers(item,map) {

    var has_image = typeof item.image !== 'undefined' && item.image !== null && item.image !== '';
    var img = (has_image ? item.image : 'https://www.skipperblogs.com/assets/img/map-icons/map-empty-marker-img.svg');

    var symIcon = L.divIcon(
        {
            html: '<img class="marker-img" src="https://www.skipperblogs.com/media/cache/th_150x150'+img+'">',
            iconSize:     [40, 40], // size of the icon
            iconAnchor:   [20, 20], // point of the icon which will correspond to marker's location
            popupAnchor:  [0, -20], // point from which the popup should open relative to the iconAnchor
            'className': 'marker-type-'+item.type
        }
    );

    var lng = parseFloat(item.lng);
    var lng_left = lng + 360;
    var lng_right = lng - 360;

    var marker = new L.Marker([item.lat,lng], {
        clickable: true,
        title: item.title,
        icon: symIcon,
        type: 'waypoint',
        extensions: null,
        url:item.url
    });
  /*  var marker_clone_left = new L.Marker([item.lat,lng_left], {
        clickable: true,
        title: item.title,
        icon: symIcon,
        type: 'waypoint',
        extensions: null
    });
    var marker_clone_right = new L.Marker([item.lat,lng_right], {
        clickable: true,
        title: item.title,
        icon: symIcon,
        type: 'waypoint',
        extensions: null
    });
*/
    var content = '<div class="tooltip-top link" data-url="'+item.url+'" ';

    if(has_image)
        content += 'style="background-image:url(/media/cache/content_th_320/'+item.image+')"';

    content +='><span class="pull-right type">'+sblang[item.type]+'<i class="fa fa-pencil-alt"></i></span>';

    if(item.type === 'video')
        content += '<i class="fa fa-play-circle" style="position: absolute;margin-left: calc(50% - 46px);margin-top: calc(50% - 90px);font-size: 100px;opacity: 0.3;"></i>';

    content += '<strong class="title">'+item.title;

    var country = '';
    if(exist(item.country))
        country = '<img class="country" src="https://www.skipperblogs.com/assets/img/country-flags/24x24/'+item.country+'.png" />';

    if(exist(item.date))
        content += '<br><small class="date">'+country+formatDate(item.date.date)+'</small>';
    else if(exist(item.country))
        content += '<br><small class="date">'+country+'</small>';

    content += '</strong>';
    content += '</div><div class="tooltip-body">';
    if(exist(item.excerpt))
        content += '<p style="margin-bottom: 0">'+item.excerpt+'</p>'
    if(exist(item.url))
        content += '<a target="_top" data-id="'+item.id+'" style="float: right" href="'+item.url+'">'+(item.type === 'gallery' ? sblang.see_gallery : (item.type === 'video' ? sblang.play : sblang.read_more))+'</a><br><br>'
    content += '</div>';


    var popup = L.responsivePopup(
        {closeButton:false, offset: new L.Point(-20,20),
            className: 'popup_item_located popup_'+(has_image ? '' : 'no-img')
        }).setContent(content);

    // We make duplicates of markers on each side worlds of the map
    marker.addTo(map).bindPopup(popup).on('mouseover', function (e) {
        this.openPopup();
        console.log($(this._icon).addClass(''));
    }).on('click', function(e) {
       if(typeof e.target.options.url !== 'undefined')
           window.location.href = e.target.options.url;
    });
   /* marker_clone_left.addTo(map).bindPopup(popup).on('mouseover', function (e) {
        this.openPopup();
    });
    marker_clone_right.addTo(map).bindPopup(popup).on('mouseover', function (e) {
        this.openPopup();
    });*/
}

function loadMapItems(map) {

    if(typeof navigation_initialized !== 'undefined' && navigation_initialized === false)
        return;

    if(window.items_loading === true || window.items_loaded === true || (typeof load_articles !== 'undefined' && !load_articles)) // In map-embeeded
        return;

    window.items_loading = true;

    $.ajax({
        url: '/map/items'+(typeof site_id !== 'undefined' ? '/'+site_id:''), // side_id In map-embeeded
        success:function (data) {
            data.forEach(function (item) {
                generateItemsMarkers(item,map);
            });
            window.items_loaded = true;
        }
    });

}

function loadTracks(map,centerOn) {
    window.track_loading = true;
    let rand = Math.random() * 100000;
    gpx = new L.GPX(url.map_data+'?'+rand, {
        async: true,
        map:map,
        load_articles: (typeof load_articles === 'undefined' || load_articles),
        display_tracking_vertex: true,
        preferCanvas: true, // needed to display_tracking_vertex
        marker_options: {
            startIconUrl: 'https://www.skipperblogs.com/assets/img/map-icons/start.png',
        }
    })
    .on('addline',function (e) {

    })
    .on('addpoint',function (e) {

        //e.line.bindTooltip(e.line.get_name()+'<br>'+parseInt(e.target.get_distance()/1852));
    })
    .on('loaded', function(e) {

        window.mapStats = {};
        window.mapStats = e.target;

        $('.info-last-pos span').html(e.target.get_last_pos());
        $('.info-last-update span').html(formatDateToMinutesAgo(e.target.get_last_updateOBJ()));
        $('.info-total-distance span').html(parseInt(e.target.get_distance_imp()));
        var avg_speed = e.target.get_avg_speed_imp();
        var pace_day = e.target.get_avg_pace_per_day();
        var max_speed = e.target.get_max_speed();
        var avg_speed_last24h_imp = e.target.get_avg_speed_last24h_imp();

        $('.info-avg-speed span').html(avg_speed === '0.0' ? '<small><i>'+sblang.map_no_enough_data+'</i></small>' : avg_speed+' '+sblang.speed_unit);
        $('.info-avg-speed-24 span').html(avg_speed_last24h_imp === '0.0' ? '<small><i>'+sblang.map_no_enough_data+'</i></small>' : avg_speed_last24h_imp+' '+sblang.speed_unit);
        $('.info-pace-day span').html(pace_day === 0 ? '<small><i>'+sblang.map_no_enough_data+'</i></small>' : pace_day+' '+sblang.miles);
        $('.info-max-speed span').html(max_speed === 0 ? '<small><i>'+sblang.map_no_enough_data+'</i></small>' : max_speed+' '+sblang.speed_unit);

        boat_marker = e.target.get_boat_marker();

        if(typeof e.target.get_boat_marker() !== 'undefined'){ // Map not initialized
            map_boat_position = e.target.get_boat_marker().getLatLng();

            var departedOn = e.target.get_start_time();
            $('.info-total-days span').html(formatDateToString(departedOn));


            if(centerOn === 'boat')
                map.panTo(e.target.get_boat_marker().getLatLng());
            else if(typeof e.target.get_track_tracking().getBounds()._northEast !== 'undefined' && typeof e.target.get_track_tracking().getBounds()._southWest !== 'undefined') { // we must have 4 corner for bounds, otherwise we load an infinit number of tiles
                map.fitBounds(e.target.get_track_tracking().getBounds(), {padding: [15,15]});
                setTimeout(function(){
                    map.setZoom(map.getZoom()-1);
                },500);
            }
            else
                map.panTo(e.target.get_boat_marker().getLatLng());

        }
        window.track_loaded = true;
    }).on('error', function() {
        window.track_loaded = true;
    })
    .addTo(map);

}
function getOnlyMapStats(){

        if(typeof map !== 'undefined' || typeof gpx !=='undefined')
            return;

        map = L.map('dummymap');
        gpx = new L.GPX(url.map_data, {
            async: true,
            map:map,
        })
        .on('loaded', function(e) {
            $('.info-last-pos span').html(e.target.get_last_pos());
            $('.info-last-update span').html(formatDateToMinutesAgo(e.target.get_last_updateOBJ()));
            $('.info-total-distance span').html(parseInt(e.target.get_distance_imp()));
            var avg_speed = e.target.get_avg_speed_imp();
            var pace_day = e.target.get_avg_pace_per_day();
            var max_speed = e.target.get_max_speed();

            $('.info-avg-speed span').html(avg_speed === '0.0' ? '<small><i>'+sblang.map_no_enough_data+'</i></small>' : avg_speed+' '+sblang.speed_unit);
            $('.info-pace-day span').html(pace_day === 0 ? '<small><i>'+sblang.map_no_enough_data+'</i></small>' : pace_day+' '+sblang.miles);
            $('.info-max-speed span').html(max_speed === 0 ? '<small><i>'+sblang.map_no_enough_data+'</i></small>' : max_speed+' '+sblang.speed_unit);

            $('.info-total-days span').html(formatDateToString(e.target.get_start_time()));

        }).addTo(map);
}
$(document).ready(function () {

    if(typeof navigation_initialized === 'undefined')
        navigation_initialized = true;//assume true

    if(typeof map_override === 'undefined' || map_override === false){


        if ($( "#map" ).length && navigation_initialized){
            mapLoader(30000);
            initMainMap($( "#map" ));
        }
        else if ($( "#windy" ).length && navigation_initialized){
            mapLoader(30000);
            initWindyMap($( "#windy" ));
        }
        else if(!navigation_initialized){
            $( "#map,#windy" ).addClass('mapnotinit').append('<div>'+sblang.map_not_init+'</div>');
        }else if($('.widget-map-stats').length > 0 ){
            getOnlyMapStats();
        }
    }
    loadArticleMiniMap();
});

(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory(require('leaflet')) :
        typeof define === 'function' && define.amd ? define(['leaflet'], factory) :
            (global.L = global.L || {}, global.L.terminator = factory(global.L));
}(this, (function (L) { 'use strict';

    L = L && L.hasOwnProperty('default') ? L['default'] : L;

    /* Terminator.js -- Overlay day/night region on a Leaflet map */

    function julian(date) {
        /* Calculate the present UTC Julian Date. Function is valid after
         * the beginning of the UNIX epoch 1970-01-01 and ignores leap
         * seconds. */
        return (date / 86400000) + 2440587.5;
    }

    function GMST(julianDay) {
        /* Calculate Greenwich Mean Sidereal Time according to
             http://aa.usno.navy.mil/faq/docs/GAST.php */
        var d = julianDay - 2451545.0;
        // Low precision equation is good enough for our purposes.
        return (18.697374558 + 24.06570982441908 * d) % 24;
    }

    var Terminator = L.Polygon.extend({
        options: {
            color: '#00',
            opacity: 0.5,
            fillColor: '#00',
            fillOpacity: 0.5,
            resolution: 2
        },

        initialize: function (options) {
            this.version = '0.1.0';
            this._R2D = 180 / Math.PI;
            this._D2R = Math.PI / 180;
            L.Util.setOptions(this, options);
            var latLng = this._compute(this.options.time);
            this.setLatLngs(latLng);
        },

        setTime: function (date) {
            this.options.time = date;
            var latLng = this._compute(date);
            this.setLatLngs(latLng);
        },

        _sunEclipticPosition: function (julianDay) {
            /* Compute the position of the Sun in ecliptic coordinates at
                 julianDay.  Following
                 http://en.wikipedia.org/wiki/Position_of_the_Sun */
            // Days since start of J2000.0
            var n = julianDay - 2451545.0;
            // mean longitude of the Sun
            var L$$1 = 280.460 + 0.9856474 * n;
            L$$1 %= 360;
            // mean anomaly of the Sun
            var g = 357.528 + 0.9856003 * n;
            g %= 360;
            // ecliptic longitude of Sun
            var lambda = L$$1 + 1.915 * Math.sin(g * this._D2R) +
                0.02 * Math.sin(2 * g * this._D2R);
            // distance from Sun in AU
            var R = 1.00014 - 0.01671 * Math.cos(g * this._D2R) -
                0.0014 * Math.cos(2 * g * this._D2R);
            return {lambda: lambda, R: R};
        },

        _eclipticObliquity: function (julianDay) {
            // Following the short term expression in
            // http://en.wikipedia.org/wiki/Axial_tilt#Obliquity_of_the_ecliptic_.28Earth.27s_axial_tilt.29
            var n = julianDay - 2451545.0;
            // Julian centuries since J2000.0
            var T = n / 36525;
            var epsilon = 23.43929111 -
                T * (46.836769 / 3600
                    - T * (0.0001831 / 3600
                        + T * (0.00200340 / 3600
                            - T * (0.576e-6 / 3600
                                - T * 4.34e-8 / 3600))));
            return epsilon;
        },

        _sunEquatorialPosition: function (sunEclLng, eclObliq) {
            /* Compute the Sun's equatorial position from its ecliptic
             * position. Inputs are expected in degrees. Outputs are in
             * degrees as well. */
            var alpha = Math.atan(Math.cos(eclObliq * this._D2R)
                * Math.tan(sunEclLng * this._D2R)) * this._R2D;
            var delta = Math.asin(Math.sin(eclObliq * this._D2R)
                * Math.sin(sunEclLng * this._D2R)) * this._R2D;

            var lQuadrant = Math.floor(sunEclLng / 90) * 90;
            var raQuadrant = Math.floor(alpha / 90) * 90;
            alpha = alpha + (lQuadrant - raQuadrant);

            return {alpha: alpha, delta: delta};
        },

        _hourAngle: function (lng, sunPos, gst) {
            /* Compute the hour angle of the sun for a longitude on
             * Earth. Return the hour angle in degrees. */
            var lst = gst + lng / 15;
            return lst * 15 - sunPos.alpha;
        },

        _latitude: function (ha, sunPos) {
            /* For a given hour angle and sun position, compute the
             * latitude of the terminator in degrees. */
            var lat = Math.atan(-Math.cos(ha * this._D2R) /
                Math.tan(sunPos.delta * this._D2R)) * this._R2D;
            return lat;
        },

        _compute: function (time) {
            var today = time ? new Date(time) : new Date();
            var julianDay = julian(today);
            var gst = GMST(julianDay);
            var latLng = [];

            var sunEclPos = this._sunEclipticPosition(julianDay);
            var eclObliq = this._eclipticObliquity(julianDay);
            var sunEqPos = this._sunEquatorialPosition(sunEclPos.lambda, eclObliq);
            for (var i = 0; i <= 720 * this.options.resolution; i++) {
                var lng = -360 + i / this.options.resolution;
                var ha = this._hourAngle(lng, sunEqPos, gst);
                latLng[i + 1] = [this._latitude(ha, sunEqPos), lng];
            }
            if (sunEqPos.delta < 0) {
                latLng[0] = [90, -360];
                latLng[latLng.length] = [90, 360];
            } else {
                latLng[0] = [-90, -360];
                latLng[latLng.length] = [-90, 360];
            }
            return latLng;
        }
    });

    function terminator(options) {
        return new Terminator(options);
    }

    return terminator;

})));
$(document).on('click','.tooltip-top.link',function () {
    window.location = $(this).data('url');
});
//Kon ami
var k = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65],
    n = 0;
$(document).keydown(function (e) {
    if (e.keyCode === k[n++]) {
        if (n === k.length) {

            var NASAGIBS_ViirsEarthAtNight2012 = L.tileLayer('https://map1.vis.earthdata.nasa.gov/wmts-webmerc/VIIRS_CityLights_2012/default/{time}/{tilematrixset}{maxZoom}/{z}/{y}/{x}.{format}', {
                attribution: 'Imagery provided by services from the Global Imagery Browse Services (GIBS), operated by the NASA/GSFC/Earth Science Data and Information System (<a href="https://earthdata.nasa.gov">ESDIS</a>) with funding provided by NASA/HQ.',
                bounds: [[-85.0511287776, -179.999999975], [85.0511287776, 179.999999975]],
                minZoom: 1,
                maxZoom: 8,
                format: 'jpg',
                time: '',
                tilematrixset: 'GoogleMapsCompatible_Level'
            }).addTo(map);
            n = 0;
            return false;
        }
    }
    else {
        n = 0;
    }
});
// In site article single view, loads the map if article has coords
function loadArticleMiniMap() {


    if($('#sidebar').length)
        var sidebar = $('#sidebar');
    else if($('#secondary').length)
        var sidebar = $('#secondary');
    else
        return;

    if(!$('#content_mini_map').length)
        return;

    var el = $('#content_mini_map').addClass('widget active');
    sidebar.prepend(el);

    var article_mini_map = L.map('content_mini_map').setView([el.data('lat'), el.data('lng')], 7);
    L.tileLayer(el.data('layer'), {
        ext: el.data('ext'),
        subdomains: el.data('subdomains'),
        maxZoom: 17,
        minZoom: 3
    }).addTo(article_mini_map);
    var icon = L.icon({
            iconUrl: '/assets/vendors/leaflet/1.6.0/images/marker-icon-2x-red.png',
            shadowUrl: '/assets/vendors/leaflet/1.6.0/images/marker-shadow.png',
            iconSize:     [25, 41], // size of the icon
            shadowSize:   [25, 41], // size of the shadow
            iconAnchor:   [11, 41], // point of the icon which will correspond to marker's location
            shadowAnchor: [11, 41],  // the same for the shadow
    });
    L.marker([el.data('lat'), el.data('lng')], {icon: icon}).addTo(article_mini_map);
}