jQuery.loadScript = function (url, callback) {
    jQuery.ajax({
        url: url,
        dataType: 'script',
        success: callback,
        async: true
    });
}
$.fn.spin = function(on,text='') {
    if(on){

        if(this.hasClass('btn-disabled'))
            return; // the button already spins

        var html = this.html();
        this.addClass('btn-disabled').html('<i class="fa fa-spin fa-spinner"></i> '+text).attr('data-html',html).attr('disabled','disabled');
    }else if(!on){

        if(!this.hasClass('btn-disabled'))
            return; // the spin has already been stopped

        this.removeClass('btn-disabled').removeAttr('disabled').html(this.attr('data-html')).attr('data-html','');
    }
};

$(document).ready(function () {
    typeof fancybox === 'function' ? $(".sbfancy").fancybox() : null;
});

$('#contact-form').submit(function (e) {
    e.preventDefault();

    var button = $('#post-contact');

    var button_val = button.val();
    var button_text = button.html();
    var button_alt_text = button.attr('data-alt-text');

    button.html(button_alt_text);
    button.val(button_alt_text);

    $('#error_message').html('');
    button.prop('disabled', true);

    $('#contact-form-success,#contact-form-errors').slideUp();


    var url = $('#contact-form').attr('action');
    $.ajax({
        type: 'POST',
        url: url,
        dataType: 'json',
        data:$('#contact-form').serialize(),
        success: function(result) {

            button.prop('disabled', false);
            button.html(button_text);
            button.val(button_val);
            if (result.success) {
                $('#contact-form')[0].reset();
                $('#contact-form-success .msg').html(result.message);
                $('#contact-form-success').slideDown();
                return false;
            } else {
                $('#contact-form-errors').slideDown();
                $('#contact-form-errors .msg').html(result.message);
            }
        },
        error: function () {

            button.prop('disabled', false);
            button.html(button_text);
            button.val(button_val);

            $('#contact-form-errors .msg').html('An error occured. Please try again');
        }
    });

});

var threadId = null;
$('.comment-reply').click(function(){
    threadId = $(this).attr('data-thread-id');
    $('#comment-form-title').text('Answer to '+$(this).attr('data-author'));
    $("html, body").stop().animate({scrollTop:$('#blog-single-comment-form').offset().top-100}, 500, 'swing');

});

$('#blog-single-comment-form').submit(function(e){
    e.preventDefault();

    var form = $(this);
    var button = $('#post-comment');

    var button_text = button.html();
    var button_val = button.val();
    button.html(button.attr('data-alt-text'));
    button.val(button.attr('data-alt-text'));

    var itemId = form.attr('data-item-id');
    var type = form.attr('data-type');
    $('#comment-result').slideUp();
    button.prop('disabled', true);

    $.ajax({
        url: form.attr('data-action'),
        type : 'POST',
        data : form.serialize()+'&threadId='+threadId+'&itemId='+itemId+'&type='+type+'&permalink='+window.location.href,
        success: function (data) {
            button.html(button_text);
            button.val(button_val);
            button.prop('disabled', false);
            $('#comment-result').slideDown();
            if(data.result===true){
                $('#form_content,#form_author,#form_email').val('');
                $('#comment-result').html('<p style="color: #2f9400">'+data.message+'</p>');
            }
            else
                $('#comment-result').html('<p style="color: #ff2e2f">'+data.message+'</p>');
        },
        error: function (data) {
            button.prop('disabled', false);
            button.html(button_text);
            button.val(button_val);
            $('#comment-result').text('Error');
        }
    });
});
/*Tool for internal Campaigns */
function getUrlParam(param) {
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    return  urlParams.get(param);
}
if(getUrlParam('source') !== null && localStorage.getItem('campaign_id')===null){
    localStorage.setItem('campaign_id',getUrlParam('source'));
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {};
    xhr.open('GET', '/campaign/source/'+getUrlParam('source'));
    xhr.send();
}

