/**
    customized for Skipperblogs
 Orignal code from https://github.com/mpetazzoni/leaflet-gpx
 */

var L = L || require('leaflet');

var _MAX_POINT_INTERVAL_MS = 15000;
var _SECOND_IN_MILLIS = 1000;
var _MINUTE_IN_MILLIS = 60 * _SECOND_IN_MILLIS;
var _HOUR_IN_MILLIS = 60 * _MINUTE_IN_MILLIS;
var _DAY_IN_MILLIS = 24 * _HOUR_IN_MILLIS;

var _GPX_STYLE_NS = 'http://www.topografix.com/GPX/gpx_style/0/2';
var avg_speed_imp = 0;
var avg_speed_24_imp = 0;
var _DEFAULT_MARKER_OPTS = {
    startIconUrl: 'pin-icon-start.png',
    endIconUrl: 'pin-icon-end.png',
    shadowUrl: '',
    wptIcons: [],
    wptIconsType: [],
    wptIconUrls : {
        '': 'pin-icon-wpt.png',
    },
    wptIconTypeUrls : {
        '': 'pin-icon-wpt.png',
    },
    pointMatchers: [],
    iconSize: [33, 45],
    shadowSize: [50, 50],
    iconAnchor: [16, 45],
    shadowAnchor: [16, 47],
    clickable: false
};
//https://github.com/slutske22/leaflet-arrowheads?tab=readme-ov-file
var arrowHeadsOptions = {
    fill: true,
    yawn : 60, //Defines the width of the opening of the arrowhead, given in degrees. The larger the angle, the wider the arrowhead.
    size: '10px', //Determines the size of the arrowhead. Accepts three types of values '250m' for meters Ideal for maps with low variance in zoom levels. Percent '15%' will render arrows whose size is that percentage of the size of the parent polyline. Pixels '15px'Will look strange at low zoom levels or for smaller parent vectors. Ideal for larger parent vectors and at higher zoom levels.
    frequency: '100px',  // How many arrowheads are rendered on a polyline. "allvertices" renders an arrowhead on each vertex. A number value renders that number of arrowheads evenly spaces across the polyline.
}
var _DEFAULT_POLYLINE_OPTS = {
    color: 'black',
    draggable: false
};
var _DEFAULT_GPX_OPTS = {
    parseElements: ['track', 'route', 'waypoint'],
    joinTrackSegments: false //true   -> distince tracking track from route tracks
};
function toDegreesMinutesAndSeconds(coordinate) {
    var absolute = Math.abs(coordinate);
    var degrees = Math.floor(absolute);
    var minutesNotTruncated = (absolute - degrees) * 60;
    var minutes = Math.floor(minutesNotTruncated);
    var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

    return degrees + "° " + minutes + "' " + seconds+'"';
}
function isDark(color) {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substring(0, 0 + 2), 16);
    const c_g = parseInt(hex.substring(2, 2 + 2), 16);
    const c_b = parseInt(hex.substring(4, 4 + 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    return brightness < 155;
}
function formatDate(date) {

    var userLang = navigator.language || navigator.userLanguage;
    var formatted_date = new Date(date);
    var options = { day: 'numeric',  month: 'long', year: 'numeric' };

    return formatted_date.toLocaleDateString(userLang, options);
}
function waypointPopupContent(extensions){

    var content = '<div class="tooltip-top">';

    content += '<span class="source">';

    if(typeof extensions.source !== 'undefined')
        if(extensions.source !== null && extensions.source !== ''){

            var source = extensions.source;
            var sourceTrans = '';
            if(extensions.source.indexOf('s_') > -1) // translated version of source
                sourceTrans = sblang[extensions.source];

            content += sourceTrans+'&nbsp;';

            if(typeof trackingSources !== 'undefined' && typeof trackingSources[source] !== 'undefined')
                content += '<img height="20" style="margin-bottom: -7px" src="'+trackingSources[source]+'">';
            else
                content += '<i class="fa fa-location-arrow"></i> ';

            content += '<br>';
        }


    if(typeof extensions.country_code !== 'undefined' && extensions.country_code.length === 2)
        content += '<span class="country"><img src="https://www.skipperblogs.com/assets/img/country-flags/24x24/'+extensions.country_code+'.png" title="'+(typeof extensions.country_name !== 'undefined'?extensions.country_name:'')+'"></span>';


    content += '</div><div class="tooltip-body">';

    if(typeof time !== 'undefined' && time !== null)
        content += time.ddmmyyyyhhmm()+'<br>';


    if (typeof extensions.course !== 'undefined' && extensions.course.length > 0)
        content +=  sblang.course + ' ' + extensions.course + '°<br>';
    if (typeof extensions.speed !== 'undefined' && extensions.speed.length > 0)
        content +=  sblang.speed + ' ' + extensions.speed + 'kts<br>';

    if (typeof extensions.weathercode !== 'undefined' || typeof extensions.temperature !== 'undefined' || typeof extensions.windspeed !== 'undefined' || typeof extensions.winddirection !== 'undefined')
        content += '<span class="weather">';

    if (typeof extensions.weathercode !== 'undefined')
        if (typeof extensions.period !== 'undefined' && extensions.period === 'night')
            content += '<img width="90" height="70" src="https://www.skipperblogs.com/assets/img/weather-icons/codes/' + extensions.weathercode + '_night.svg">';
        else
            content += '<img width="90" height="70" src="https://www.skipperblogs.com/assets/img/weather-icons/codes/' + extensions.weathercode + '.svg">';

    if (typeof extensions.pressure !== 'undefined')
        content += '<i><img width="30" src="https://www.skipperblogs.com/assets/img/weather-icons/monochrome/raw/wi_barometer_white.svg"> ' + extensions.pressure + ' hPa</i>';
    if (typeof extensions.temperature !== 'undefined')
        content += '<i><img width="30" src="https://www.skipperblogs.com/assets/img/weather-icons/monochrome/raw/wi_thermometer-celsius_white.svg"> ' + extensions.temperature + '°C</i>';
    if (typeof extensions.windspeed !== 'undefined')
        content += '<i><img width="30" src="https://www.skipperblogs.com/assets/img/weather-icons/monochrome/raw/wi_windsock_white.svg"> ' + extensions.windspeed +' '+ sblang.speed_unit + ' ' + extensions.winddirection + '</i>';

    if (typeof extensions.weathercode !== 'undefined' || typeof extensions.temperature !== 'undefined' || typeof extensions.windspeed !== 'undefined' || typeof extensions.winddirection !== 'undefined')
        content += '</span>';

    if (typeof extensions.data !== 'undefined')
        content += '<div class="data">' + extensions.data + '</div>';
    if (typeof extensions.text !== 'undefined')
        content += '<p>' + extensions.text + '</p>';

    content += '</div>';
    return  content;
}

function deg_to_dms(deg, type) {
    if(type === 'lat'){
        var latitude = toDegreesMinutesAndSeconds(deg);
        var latitudeCardinal = deg >= 0 ? "N" : "S";
        return latitude + " " + latitudeCardinal;
    }
    else{
        var longitude = toDegreesMinutesAndSeconds(deg);
        var longitudeCardinal = deg >= 0 ? "E" : "W";
        return longitude + " " + longitudeCardinal;
    }


}
function isCrossMeridian(latLngA, latLngB) {

    if (latLngA instanceof L.LatLng && latLngB instanceof L.LatLng) {
        // Returns true if the signs are not the same.
        return Math.sign(latLngA.lng) * Math.sign(latLngB.lng) < 0;
    } else {
        throw new Error('In order to calculate whether two LatLngs cross a meridian, two valid LatLngs are required.');
    }
}
function isBreakRingAnteMeridian(latLngA, latLngB) {
    if (latLngA instanceof L.LatLng && latLngB instanceof L.LatLng) {
        return isCrossMeridian(latLngA, latLngB)  &&
            (360 - Math.abs(latLngA.lng) - Math.abs(latLngB.lng) < 180);

    } else {
        throw new Error('In order to calculate whether the ring created by two LatLngs should be broken, two valid LatLngs are required.');
    }
}
function anteMeridian(coords){

    if(!coords || coords.length < 2)
        return coords;

    var has_crossed = false;
    var crossed_east_west = true;
    var new_coords = [];

    for (var i = 0; i < coords.length; i++) {

        var current_latlng = coords[i];
        var prev_latlng = (typeof coords[i-1] === 'undefined' ? coords[i] : coords[i-1]);


        if(isBreakRingAnteMeridian(prev_latlng,current_latlng)){
            has_crossed = !has_crossed;
            crossed_east_west = prev_latlng.lng > current_latlng.lng;
        }

        var delta = 0;
        if(has_crossed)
            if(crossed_east_west)
                delta = 360;
            else
                delta = -360;


        new_coords.push({lat:current_latlng.lat,lng:(current_latlng.lng+delta),meta:current_latlng.meta});
    }
    return new_coords;
}
Date.prototype.ddmmyyyyhhmm = function() {
    //Grab each of your components
    var yyyy = this.getFullYear().toString();
    var MM = (this.getMonth()+1).toString();
    var dd  = this.getDate().toString();
    var hh = this.getHours().toString();
    var mm = this.getMinutes().toString();
    var ss = this.getSeconds().toString();
    var offset = this.getTimezoneOffset(); // if your time zone is GMT+2, -120 will be returned.
    var timezone = offset/-60;
    if(timezone > 0)
        timezone = '+'+timezone; // result UTC+2 instead of UTC2

    //Returns your formatted result
    return  (dd[1]?dd:"0"+dd[0]) + '.' + (MM[1]?MM:"0"+MM[0]) + '.' +  + yyyy + ' @' + (hh[1]?hh:"0"+hh[0]) + 'h' + (mm[1]?mm:"0"+mm[0])+ ' UTC'+timezone;
};
L.GPX = L.FeatureGroup.extend({
    initialize: function(gpx, options) {

        options.max_point_interval = options.max_point_interval || _MAX_POINT_INTERVAL_MS;

        options.marker_options = this._merge_objs(
            _DEFAULT_MARKER_OPTS,
            options.marker_options || {});
        options.polyline_options = options.polyline_options || {};
        options.gpx_options = this._merge_objs(
            _DEFAULT_GPX_OPTS,
            options.gpx_options || {});
        if(typeof options.load_markers === 'undefined')
            options.load_markers = true;
        if(typeof options.display_arrows === 'undefined')
            options.display_arrows = true;
        if(typeof options.load_articles === 'undefined')
            options.load_articles = true;
        if(typeof options.display_tracking_vertex === 'undefined')
            options.display_tracking_vertex = true;
        if(typeof options.load_only_tracking === 'undefined')
            options.load_only_tracking = false;



        L.Util.setOptions(this, options);

        // Base icon class for track pins.
        L.GPXTrackIcon = L.Icon.extend({ options: options.marker_options });

        this._gpx = gpx;
        this._gpx_backup = gpx;
        this.map = options.map;
        this._layers = {};
        this._init_info();

        if (gpx) {
            this._parse(gpx, options, this.options.async);
        }
    },
    get_duration_string: function(duration, hidems) {
        var s = '';

        if (duration >= _DAY_IN_MILLIS) {
            s += Math.floor(duration / _DAY_IN_MILLIS) + 'd ';
            duration = duration % _DAY_IN_MILLIS;
        }

        if (duration >= _HOUR_IN_MILLIS) {
            s += Math.floor(duration / _HOUR_IN_MILLIS) + ':';
            duration = duration % _HOUR_IN_MILLIS;
        }

        var mins = Math.floor(duration / _MINUTE_IN_MILLIS);
        duration = duration % _MINUTE_IN_MILLIS;
        if (mins < 10) s += '0';
        s += mins + '\'';

        var secs = Math.floor(duration / _SECOND_IN_MILLIS);
        duration = duration % _SECOND_IN_MILLIS;
        if (secs < 10) s += '0';
        s += secs;

        if (!hidems && duration > 0) s += '.' + Math.round(Math.floor(duration)*1000)/1000;
        else s += '"';

        return s;
    },

    get_duration_string_iso: function(duration, hidems) {
        var s = this.get_duration_string(duration, hidems);
        return s.replace("'",':').replace('"','');
    },

    // Public methods
    to_miles:            function(v) { return v / 1.852; },
    to_ft:               function(v) { return v * 3.28084; },
    m_to_km:             function(v) { return v / 1000; },
    m_to_mi:             function(v) { return v / 1852; },
    ms_to_kmh:           function(v) { return v * 3.6; },
    ms_to_mih:           function(v) { return v / 1609.34 * 3600; },

    get_boat_marker:     function() { return this._info.boat_marker; },
    get_track_tracking:  function() { return this._info.track_tracking; },
    get_name:            function(id) { var r = null; this._info.tracks.forEach(t => {if(t.id === id)r=t.track_name}); return r; },
    get_color:           function(id) { var r = null; this._info.tracks.forEach(t => {if(t.id === id)r=t.color}); return r; },
    get_weight:          function(id) { var r = null; this._info.tracks.forEach(t => {if(t.id === id)r=t.weight}); return r; },
    get_dasharray:       function(id) { var r = null; this._info.tracks.forEach(t => {if(t.id === id)r=t.dasharray}); return r; },
    get_dashoffset:      function(id) { var r = null; this._info.tracks.forEach(t => {if(t.id === id)r=t.dashoffset}); return r; },
    get_desc:            function() { return this._info.desc; },
    get_last_pos:        function() { return this._info.last_pos; },
    get_last_update:     function() { return this._info.last_update; },
    get_last_updateISO:  function() { return this._info.last_updateISO; },
    get_last_updateOBJ:  function() { return this._info.last_updateOBJ; },
    get_author:          function() { return this._info.author; },
    get_copyright:       function() { return this._info.copyright; },
    get_distance:        function() { return this._info.length; },
    get_distance_imp:    function() { return this.to_miles(this.m_to_km(this.get_distance())); },

    get_start_time:      function() { return this._info.duration.start; },
    get_end_time:        function() { return this._info.duration.end; },
    get_moving_time:     function() { return this._info.duration.moving; },
    get_total_time:      function() { return this._info.duration.total; },
    get_max_speed:       function() { return this._info.max_speed; },

    get_avg_speed_imp:  function(){ return avg_speed_imp.toFixed(1);},
    get_avg_speed_last24h_imp:  function(){ return avg_speed_24_imp.toFixed(1);},
    get_avg_speed_imp_float:  function(){ return avg_speed_imp;},
    get_avg_pace_per_day:  function(){ return parseInt(avg_speed_imp *24); },

    get_moving_pace:     function() { return this.get_moving_time() / this.m_to_km(this.get_distance()); },
    get_moving_pace_imp: function() { return this.get_moving_time() / this.get_distance_imp(); },

    get_moving_speed:    function() { return this.m_to_km(this.get_distance()) / (this.get_moving_time() / (3600 * 1000)) ; },
    get_moving_speed_imp:function() { return this.to_miles(this.m_to_km(this.get_distance())) / (this.get_moving_time() / (3600 * 1000)) ; },

    get_total_speed:     function() { return this.m_to_km(this.get_distance()) / (this.get_total_time() / (3600 * 1000)); },
    get_total_speed_imp: function() { return this.to_miles(this.m_to_km(this.get_distance())) / (this.get_total_time() / (3600 * 1000)); },
    get_average_distance_per_day_imp: function(){

    },
    get_elevation_gain:     function() { return this._info.elevation.gain; },
    get_elevation_loss:     function() { return this._info.elevation.loss; },
    get_elevation_gain_imp: function() { return this.to_ft(this.get_elevation_gain()); },
    get_elevation_loss_imp: function() { return this.to_ft(this.get_elevation_loss()); },
    get_elevation_data:     function() {
        var _this = this;
        return this._info.elevation._points.map(
            function(p) { return _this._prepare_data_point(p, _this.m_to_km, null,
                function(a, b) { return a.toFixed(2) + ' km, ' + b.toFixed(0) + ' m'; });
            });
    },
    get_elevation_data_imp: function() {
        var _this = this;
        return this._info.elevation._points.map(
            function(p) { return _this._prepare_data_point(p, _this.m_to_mi, _this.to_ft,
                function(a, b) { return a.toFixed(2) + ' mi, ' + b.toFixed(0) + ' ft'; });
            });
    },
    get_elevation_max:      function() { return this._info.elevation.max; },
    get_elevation_min:      function() { return this._info.elevation.min; },
    get_elevation_max_imp:  function() { return this.to_ft(this.get_elevation_max()); },
    get_elevation_min_imp:  function() { return this.to_ft(this.get_elevation_min()); },

    get_speed_data:         function() {
        var _this = this;
        return this._info.speed._points.map(
            function(p) { return _this._prepare_data_point(p, _this.m_to_km, _this.ms_to_kmh,
                function(a, b) { return a.toFixed(2) + ' km, ' + b.toFixed(2) + ' km/h'; });
            });
    },
    get_speed_data_imp: function() {
        var _this = this;
        return this._info.speed._points.map(
            function(p) { return _this._prepare_data_point(p, _this.m_to_mi, _this.ms_to_mih,
                function(a, b) { return a.toFixed(2) + ' mi, ' + b.toFixed(2) + ' mi/h'; });
            });
    },
    get_average_hr:         function() { return this._info.hr.avg; },
    get_average_temp:         function() { return this._info.atemp.avg; },
    get_average_cadence:         function() { return this._info.cad.avg; },
    get_heartrate_data:     function() {
        var _this = this;
        return this._info.hr._points.map(
            function(p) { return _this._prepare_data_point(p, _this.m_to_km, null,
                function(a, b) { return a.toFixed(2) + ' km, ' + b.toFixed(0) + ' bpm'; });
            });
    },
    get_heartrate_data_imp: function() {
        var _this = this;
        return this._info.hr._points.map(
            function(p) { return _this._prepare_data_point(p, _this.m_to_mi, null,
                function(a, b) { return a.toFixed(2) + ' mi, ' + b.toFixed(0) + ' bpm'; });
            });
    },
    get_cadence_data:     function() {
        var _this = this;
        return this._info.cad._points.map(
            function(p) { return _this._prepare_data_point(p, _this.m_to_km, null,
                function(a, b) { return a.toFixed(2) + ' km, ' + b.toFixed(0) + ' rpm'; });
            });
    },
    get_temp_data:     function() {
        var _this = this;
        return this._info.atemp._points.map(
            function(p) { return _this._prepare_data_point(p, _this.m_to_km, null,
                function(a, b) { return a.toFixed(2) + ' km, ' + b.toFixed(0) + ' degrees'; });
            });
    },
    get_cadence_data_imp:     function() {
        var _this = this;
        return this._info.cad._points.map(
            function(p) { return _this._prepare_data_point(p, _this.m_to_mi, null,
                function(a, b) { return a.toFixed(2) + ' mi, ' + b.toFixed(0) + ' rpm'; });
            });
    },
    get_temp_data_imp:     function() {
        var _this = this;
        return this._info.atemp._points.map(
            function(p) { return _this._prepare_data_point(p, _this.m_to_mi, null,
                function(a, b) { return a.toFixed(2) + ' mi, ' + b.toFixed(0) + ' degrees'; });
            });
    },

    reload: function(callback=null,url_override=null) {
        if(url_override)
            var url = url_override;
        else
            var url = this._gpx;
        // we force to reload asset

        if(url.split('?v').length < 2) // does the url already contain ?v=...
            url += '?v=';
        url += Math.floor(Math.random() * 100000);

        this._init_info();
        this.clearLayers();
        this._parse(url, this.options, this.options.async,callback);
    },
    reset: function(callback=null) {

        this._init_info();
        this.clearLayers();
        this._parse(this._gpx_backup , this.options, this.options.async,callback);
    },

    // Private methods
    _merge_objs: function(a, b) {
        var _ = {};
        for (var attr in a) { _[attr] = a[attr]; }
        for (var attr in b) { _[attr] = b[attr]; }
        return _;
    },

    _prepare_data_point: function(p, trans1, trans2, trans_tooltip) {
        var r = [trans1 && trans1(p[0]) || p[0], trans2 && trans2(p[1]) || p[1]];
        r.push(trans_tooltip && trans_tooltip(r[0], r[1]) || (r[0] + ': ' + r[1]));
        return r;
    },

    _init_info: function() {
        this._info = {
            name: null,
            length: 0.0,
            elevation: {gain: 0.0, loss: 0.0, max: 0.0, min: Infinity, _points: []},
            speed : {max: 0.0, _points: []},
            hr: {avg: 0, _total: 0, _points: []},
            duration: {start: null, end: null, moving: 0, total: 0},
            atemp: {avg: 0, _total: 0, _points: []},
            cad: {avg: 0, _total: 0, _points: []}
        };
    },

    _load_xml: function(url, cb, options, async) {
        if (async == undefined) async = this.options.async;
        if (options == undefined) options = this.options;

        var req = new window.XMLHttpRequest();
        req.open('GET', url, async);
        try {
            req.overrideMimeType('text/xml'); // unsupported by IE
        } catch(e) {}
        req.onreadystatechange = function() {
            if (req.readyState != 4) return;
            if(req.status == 200) cb(req.responseXML, options);
        };
        req.send(null);
    },

    _parse: function(input, options, async,callback=null) {
        var _this = this;
        var cb = function(gpx, options) {
            var layers = _this._parse_gpx_data(gpx, options);
            if (!layers) {
                _this.fire('error', { err: 'No parseable layers of type(s) ' + JSON.stringify(options.gpx_options.parseElements) });
                return;
            }
            _this.addLayer(layers);
            _this.fire('loaded', { layers: layers, element: gpx });
        }
        if (input.substr(0,1)==='<') { // direct XML has to start with a <
            var parser = new DOMParser();
            if (async) {
                setTimeout(function() {
                    cb(parser.parseFromString(input, "text/xml"), options);
                });
            } else {
                cb(parser.parseFromString(input, "text/xml"), options);
            }
        } else {
            this._load_xml(input, cb, options, async);
        }
        if(callback)
            callback();
    },

    _parse_gpx_data: function(xml, options) {
        var i, t, l, el, layers = [];
        var map = options.map;
        var track_name = (typeof xml.getElementsByTagName('track_name') !=='undefined' ? xml.getElementsByTagName('track_name') : '');
        var track_type = (typeof xml.getElementsByTagName('track_type') !=='undefined' ? xml.getElementsByTagName('track_type') : '');
        var ids = (typeof xml.getElementsByTagName('track_id') !=='undefined' ? xml.getElementsByTagName('track_id') : '');
        var color = (typeof xml.getElementsByTagName('color') !=='undefined' ? xml.getElementsByTagName('color') : '');
        var weight = (typeof xml.getElementsByTagName('weight') !=='undefined' ? xml.getElementsByTagName('weight') : '');
        var dasharray = (typeof xml.getElementsByTagName('dasharray') !=='undefined' ? xml.getElementsByTagName('dasharray') : '');
        var dashoffset = (typeof xml.getElementsByTagName('dashoffset') !=='undefined' ? xml.getElementsByTagName('dashoffset') : '');


        this._info.tracks = [];
        this._info.max_speed = 0;

        for(var i=0; i< ids.length;i++){
            this._info.tracks.push({id:ids[i].textContent});
        }

        for(var i=0; i< track_name.length;i++){
           if(typeof this._info.tracks[i] !== 'undefined')
            this._info.tracks[i].track_name = track_name[i].textContent;
        }
        for(var i=0; i< color.length;i++){
            if(typeof this._info.tracks[i] !== 'undefined')
            this._info.tracks[i].color = color[i].textContent;
        }
        for(var i=0; i< weight.length;i++){
            if(typeof this._info.tracks[i] !== 'undefined')
            this._info.tracks[i].weight = weight[i].textContent;
        }
        for(var i=0; i< dasharray.length;i++){
            if(typeof this._info.tracks[i] !== 'undefined')
            this._info.tracks[i].dasharray = dasharray[i].textContent;
        }
        for(var i=0; i< dashoffset.length;i++){
            if(typeof this._info.tracks[i] !== 'undefined')
            this._info.tracks[i].dashoffset = dashoffset[i].textContent;
        }


        var desc = xml.getElementsByTagName('desc');
        if (desc.length > 0) {
            this._info.desc = desc[0].textContent;
        }
        var author = xml.getElementsByTagName('author');
        if (author.length > 0) {
            this._info.author = author[0].textContent;
        }
        var copyright = xml.getElementsByTagName('copyright');
        if (copyright.length > 0) {
            this._info.copyright = copyright[0].textContent;
        }

        var parseElements = options.gpx_options.parseElements;


        if (parseElements.indexOf('route') > -1 && (typeof gpx_import_routes === 'undefined' || (typeof gpx_import_routes !== 'undefined' && gpx_import_routes))) {

            // routes are <rtept> tags inside <rte> sections
            var routes = xml.getElementsByTagName('rte');
            for (i = 0; i < routes.length; i++) {
                var track_options = {
                    boat_icon:'',
                    track_type:'route',
                    track_id:null,
                };
                layers = layers.concat(this._parse_segment(routes[i], options, {}, 'rtept',track_options));
            }
        }
        var circleMarkers = [];
        if (parseElements.indexOf('track') > -1) {
            // tracks are <trkpt> tags in one or more <trkseg> sections in each <trk>
            var tracks = xml.getElementsByTagName('trk');

            for (i = 0; i < tracks.length; i++) {
                var track = tracks[i];

                var polyline_options = this._extract_styling(track);

                var idEl = typeof track.getElementsByTagName('track_id') !=='undefined' ? track.getElementsByTagName('track_id') : '';
                var track_id = typeof idEl[0] !== 'undefined' ? idEl[0].textContent : '';
                var typeEl = typeof track.getElementsByTagName('track_type') !=='undefined' ? track.getElementsByTagName('track_type') : '';
                var track_type = typeof typeEl[0] !== 'undefined' ? typeEl[0].textContent : '';
                var boatIconEl = typeof track.getElementsByTagName('boat_icon') !=='undefined' ? track.getElementsByTagName('boat_icon') : '';
                var boat_icon = typeof boatIconEl[0] !== 'undefined' ? boatIconEl[0].textContent : null;

                if(polyline_options.hidden && track_type !== 'tracking' && (typeof display_hidden_tracks === 'undefined' || !display_hidden_tracks))
                    continue; // skip this track


                var track_options = {
                    boat_icon:boat_icon,
                    track_type:track_type,
                    track_id:track_id,
                    include_in_stats:polyline_options.include_in_stats || track_type === 'tracking',
                    hidden:polyline_options.hidden && track_type !== 'tracking'
                };


                circleMarkers[i] = [];

                if (options.gpx_options.joinTrackSegments) {

                    layers = layers.concat(this._parse_segment(track, options, polyline_options, 'trkpt',track_options));

                } else {

                    var segments = track.getElementsByTagName('trkseg');
                    
                    if(track.getElementsByTagName('name').length > 0)
                        track_options.track_name = track.getElementsByTagName('name')[0].textContent;
                    else
                        track_options.track_name = 'untitled';

                    for (j = 0; j < segments.length; j++) {

                        layers = layers.concat(this._parse_segment(segments[j], options, polyline_options, 'trkpt',track_options));

                        if(options.display_tracking_vertex && track_type === 'tracking'){

                            var style = {
                                radius: 4,
                                stroke: true,
                                weight: 2, // stroke weight
                                opacity: 0.6, // stoke opacity
                                color: (isDark(polyline_options.color)?'#000000':'#ffffff'), // stroke color
                                fill: true,
                                fillColor: polyline_options.color,
                                fillOpacity: 1,
                                type:'track'
                            };

                            if(map.getZoom() < 8){
                                style.radius = 0;
                                style.opacity = 0;
                                style.weight = 0;
                                style.fillOpacity = 0;
                            }
                            else if(map.getZoom() >= 8){
                                style.radius = (map.getZoom()/2) + 1;
                                style.opacity = 0.6;
                                style.weight = 2;
                                style.fillOpacity = 1;
                            }

                            var trkpts = segments[j].getElementsByTagName('trkpt');


                            for (var r = 0; r < trkpts.length; r++) {

                                var latLng = new L.LatLng(trkpts[r].getAttribute('lat'), trkpts[r].getAttribute('lon'));

                                var time = trkpts[r].getElementsByTagName('time');
                                var date = null;

                                if (time.length > 0)
                                    date = new Date(Date.parse(time[0].textContent));

                                var extEl = trkpts[r].getElementsByTagName('extensions');
                                var extensions = [];
                                if(extEl.length > 0){
                                    extEl[0].childNodes.forEach(function (ext) {
                                        extensions[ext.nodeName] = ext.textContent;
                                    });
                                }

                                //if(
                                //    typeof extensions.source === 'undefined'
                                //    || extensions.source === null
                                //    || extensions.source === ''
                                //    || extensions.source === 'source.'
                                //    || extensions.source.includes('manu')
                               // )
                              //      continue; // Only add vertexes with info to real tracking waypoints

                                var content = '<div class="tooltip-top">';

                                content += '<span class="source">';

                                if(typeof extensions.source !== 'undefined')
                                    if(extensions.source !== null && extensions.source !== ''){
                                        var source = extensions.source;
                                        if(extensions.source.indexOf('s_') > -1) // translated version of source
                                            source = sblang[extensions.source];

                                        if(typeof trackingSources !== 'undefined' && typeof trackingSources[extensions.source] !== 'undefined')
                                            content += '<img height="20" style="margin-bottom: -7px" src="'+trackingSources[extensions.source]+'">';
                                        else
                                            content += '<i class="fa fa-location-arrow"></i>';

                                        content += '&nbsp;'+source+'<br>';
                                    }


                                content += '</span>';


                                content += '</div><div class="tooltip-body">';

                                if(date !== null)
                                    content += date.ddmmyyyyhhmm()+'<br>';

                               // content += deg_to_dms(latLng.lat,'lat')+' '+deg_to_dms(latLng.lng,'lng')+'<br>';
                                if(typeof extensions.country_code !== 'undefined' && extensions.country_code.length === 2)
                                    content += '<span class="country"><img src="https://www.skipperblogs.com/assets/img/country-flags/24x24/'+extensions.country_code+'.png" title="'+(typeof extensions.country_name !== 'undefined'?extensions.country_name:'')+'"></span>';


                                if (typeof extensions.course !== 'undefined' && extensions.course.length > 0)
                                    content +=  sblang.course + ' ' + extensions.course + '°<br>';
                                if (typeof extensions.speed !== 'undefined' && extensions.speed.length > 0)
                                    content +=  sblang.speed + ' ' + extensions.speed + 'kts<br>';



                                if (typeof extensions.weathercode !== 'undefined' || typeof extensions.temperature !== 'undefined' || typeof extensions.windspeed !== 'undefined' || typeof extensions.winddirection !== 'undefined')
                                    content += '<span class="weather">';

                                if (typeof extensions.weathercode !== 'undefined')
                                    if (typeof extensions.period !== 'undefined' && extensions.period === 'night')
                                        content += '<span class="code"><img width="90" height="70" src="https://www.skipperblogs.com/assets/img/weather-icons/codes/' + extensions.weathercode + '_night.svg"></span>';
                                    else
                                        content += '<span class="code"><img width="90" height="70" src="https://www.skipperblogs.com/assets/img/weather-icons/codes/' + extensions.weathercode + '.svg"></span>';

                                if (typeof extensions.pressure !== 'undefined')
                                    content += '<i><img width="30" src="https://www.skipperblogs.com/assets/img/weather-icons/monochrome/raw/wi_barometer_white.svg"> ' + extensions.pressure + ' hPa</i>';
                                if (typeof extensions.temperature !== 'undefined')
                                    content += '<i><img width="30" src="https://www.skipperblogs.com/assets/img/weather-icons/monochrome/raw/wi_thermometer-celsius_white.svg"> ' + extensions.temperature + '°C</i>';
                                if (typeof extensions.windspeed !== 'undefined')
                                    content += '<i><img width="30" src="https://www.skipperblogs.com/assets/img/weather-icons/monochrome/raw/wi_windsock_white.svg"> ' + extensions.windspeed +' '+ sblang.speed_unit + ' ' + extensions.winddirection + '</i>';

                                if (typeof extensions.weathercode !== 'undefined' || typeof extensions.temperature !== 'undefined' || typeof extensions.windspeed !== 'undefined' || typeof extensions.winddirection !== 'undefined')
                                    content += '</span>';

                                if (typeof extensions.data !== 'undefined')
                                    content += '<div class="data">' + extensions.data + '</div>';
                                if (typeof extensions.text !== 'undefined')
                                    content += '<p>' + extensions.text + '</p>';

                                content += '</div>';

                                circleMarkers[i][r] = L.circleMarker(latLng,style).addTo(map);

                               /* var empty_top = (typeof extensions.source === 'undefined' &&  typeof extensions.country_code === 'undefined');
                                circleMarkers[r].bindTooltip(content,{
                                    className: 'popup_vertex no-img '+(empty_top?'simple':''),

                                });*/

                                var popup = L.responsivePopup({className: 'popup_waypoint_text', closeButton:false, offset: new L.Point(-20,20)}).setContent(content);
                                circleMarkers[i][r].addTo(map).bindPopup(popup).on('mouseover', function () {
                                    this.openPopup();
                                }).on('mouseout', function () {
                                    this.closePopup();
                                });
                                setTimeout(function () {
                                    // we want the circlemarker over the track after all instanciation
                                //    typeof circleMarkers[i][r] !== 'undefined'? circleMarkers[i][r].bringToFront():false;
                                },500);
                            }
                        }
                    }
                }
                // only display on low zooms
                map.on('zoomend', function() {

                    circleMarkers.forEach(function (track) {
                        track.forEach(function (marker) {
                            if(map.getZoom() < 7){
                                style.radius = 0;
                                style.weight = 0;
                                style.opacity = 0.6;
                                style.fillOpacity = 0;
                                marker.setStyle(style);
                            }
                            else if(map.getZoom() >= 6){
                                style.radius = (map.getZoom()/1.3);
                                style.opacity = 0.6;
                                style.weight = 2;
                                style.fillOpacity = 1;
                                marker.setStyle(style);
                            }
                        });
                    });
                });
            }
        }
        this.fire('addcircleMarkers', { markers: circleMarkers });



        this._info.hr.avg = Math.round(this._info.hr._total / this._info.hr._points.length);
        this._info.cad.avg = Math.round(this._info.cad._total / this._info.cad._points.length);
        this._info.atemp.avg = Math.round(this._info.atemp._total / this._info.atemp._points.length);


        // parse waypoints and add markers for each of them
        if (parseElements.indexOf('waypoint') > -1) {

            el = xml.getElementsByTagName('wpt');
            for (i = 0; i < el.length; i++) {

                var ll = new L.LatLng(
                    el[i].getAttribute('lat'),
                    el[i].getAttribute('lon'));


                var nameEl = el[i].getElementsByTagName('name');
                var name = nameEl.length > 0 ? nameEl[0].textContent : '';
                var content = '';
                var popupType = 'popup';

                var extEl = el[i].getElementsByTagName('extensions');

                var extensions = [];
                if(extEl.length > 0){
                    extEl[0].childNodes.forEach(function (ext) {
                        extensions[ext.nodeName] = ext.textContent;
                    });
                }


                var descEl = el[i].getElementsByTagName('desc');
                var desc = descEl.length > 0 ? descEl[0].textContent : '';

                var symEl = el[i].getElementsByTagName('sym');
                var symKey = symEl.length > 0 ? symEl[0].textContent : null;

                var typeEl = el[i].getElementsByTagName('type');
                var typeKey = typeEl.length > 0 ? typeEl[0].textContent : null;

                var timeEL = el[i].getElementsByTagName('time');
                var time = timeEL.length > 0 ? new Date(timeEL[0].textContent) : null;

                /*
                 * Add waypoint marker based on the waypoint symbol key.
                 *
                 * First look for a configured icon for that symKey. If not found, look
                 * for a configured icon URL for that symKey and build an icon from it.
                 * If none of those match, look through the point matchers for a match
                 * on the waypoint's name.
                 *
                 * Otherwise, fall back to the default icon if one was configured, or
                 * finally to the default icon URL, if one was configured.
                 */
                var wptIcons = options.marker_options.wptIcons;
                var wptIconUrls = options.marker_options.wptIconUrls;
                var wptIconsType = options.marker_options.wptIconsType;
                var wptIconTypeUrls = options.marker_options.wptIconTypeUrls;
                var ptMatchers = options.marker_options.pointMatchers || [];
                var symIcon;
                if (wptIcons && symKey && wptIcons[symKey]) {
                    symIcon = wptIcons[symKey];
                } else if (wptIconsType && typeKey && wptIconsType[typeKey]) {
                    symIcon = wptIconsType[typeKey];
                } else if (wptIconUrls && symKey && wptIconUrls[symKey]) {
                    symIcon = new L.GPXTrackIcon({iconUrl: wptIconUrls[symKey]});
                } else if (wptIconTypeUrls && typeKey && wptIconTypeUrls[typeKey]) {
                    symIcon = new L.GPXTrackIcon({iconUrl: wptIconTypeUrls[typeKey]});
                } else if (ptMatchers.length > 0) {
                    for (var j = 0; j < ptMatchers.length; j++) {
                        if (ptMatchers[j].regex.test(name)) {
                            symIcon = ptMatchers[j].icon;
                            break;
                        }
                    }
                } else if (wptIcons && wptIcons['']) {
                    symIcon = wptIcons[''];
                } else if (wptIconUrls && wptIconUrls['']) {

                    symIcon = new L.GPXTrackIcon({iconUrl: wptIconUrls['']});
                    symIcon = L.icon({
                        iconUrl:   'https://www.skipperblogs.com/assets/img/map-icons/waypoint.svg',
                        iconSize:     [14, 14], // size of the icon
                        iconAnchor:   [7, 7], // point of the icon which will correspond to marker's location
                        popupAnchor:  [2, -20], // point from which the popup should open relative to the iconAnchor
                        'className': 'article-marker-'
                    });


                    // if it's the last point, the data are already added with boat marker
                    if(
                        typeof extensions.point_type !== 'undefined'
                        && extensions.point_type === 'waypoint'
                        && parseFloat(el[i].getAttribute('lat')) !== this._info.boat_marker._latlng.lat
                        && parseFloat(el[i].getAttribute('lon')) !== this._info.boat_marker._latlng.lng

                    ){
                        popupType = 'tooltip';
                        content = waypointPopupContent(extensions);
                    }

                }
                /*var country = '';

                if(typeof extensions.point_type !== 'undefined')
                    if(extensions.point_type === 'blog'){

                        content = '<div class="tooltip-top link" data-url="'+extensions.url+'" ';

                        if(typeof extensions.cover_img !== 'undefined')
                            if(extensions.cover_img !== null)
                                content += 'style="background-image:url('+extensions.cover_img+')"';

                        content +='><span class="pull-right type">'+sblang.article+'<i class="fa fa-pencil-alt"></i></span>';

                        if(typeof extensions.title !== 'undefined')
                            content += '<strong class="title">'+extensions.title;


                        if(typeof extensions.country_code !== 'undefined' && extensions.country_code.length === 2)
                            country = '<img class="country" src="https://www.skipperblogs.com/assets/img/country-flags/24x24/'+extensions.country_code+'.png" title="'+(typeof extensions.country_name !== 'undefined'?extensions.country_name:'')+'">';

                        if(typeof extensions.date !== 'undefined')
                            content += '<br><small class="date">'+country+extensions.date+'</small>';

                        content += '</strong>';
                        content += '</div><div class="tooltip-body">';

                        if(typeof extensions.excerpt !== 'undefined')
                            content += '<p style="margin-bottom: 0">'+extensions.excerpt+'</p>'

                        if(typeof extensions.url !== 'undefined')
                            content += '<a data-id="'+extensions.id+'" style="float: right" href="'+extensions.url+'">'+sblang.read_more+'</a><br><br>'



                        content += '</div>';

                    }

                else if(extensions.point_type === 'video'){

                    content = '<div class="tooltip-top link" data-url="'+extensions.url+'"';

                    if(typeof extensions.cover_img !== 'undefined')
                        if(extensions.cover_img !== null)
                            content += 'style="background-image:url('+extensions.cover_img+')"';

                    content +='><span class="pull-right type">'+sblang.video+'<i class="fa fa-video"></i></span>';

                    if(typeof extensions.title !== 'undefined')
                        content += '<strong class="title">'+extensions.title;


                    if(typeof extensions.country_code !== 'undefined' && extensions.country_code.length === 2)
                        country = '<img class="country" src="https://www.skipperblogs.com/assets/img/country-flags/24x24/'+extensions.country_code+'.png" title="'+(typeof extensions.country_name !== 'undefined'?extensions.country_name:'')+'">';

                    if(typeof extensions.date !== 'undefined')
                        content += '<br><small class="date">'+country+extensions.date+'</small>';

                    content += '</strong>';
                    content += '</div><div class="tooltip-body">';

                    if(typeof extensions.excerpt !== 'undefined')
                        content += '<p style="margin-bottom: 0">'+extensions.excerpt+'</p>'

                    if(typeof extensions.url !== 'undefined')
                        content += '<a data-id="'+extensions.id+'" style="float: right" href="'+extensions.url+'">'+sblang.play+'</a><br><br>'



                    content += '</div>';

                }

                    else if(extensions.point_type === 'gallery'){

                        content = '<div class="tooltip-top link" data-url="'+extensions.url+'"';

                        if(typeof extensions.cover_img !== 'undefined')
                            if(extensions.cover_img !== null)
                                content += 'style="background-image:url('+extensions.cover_img+')"';

                        content +='><span class="pull-right type">'+sblang.gallery+'<i class="fa fa-images"></i></span>';

                        if(typeof extensions.title !== 'undefined')
                            content += '<strong class="title">'+extensions.title;


                        if(typeof extensions.country_code !== 'undefined' && extensions.country_code.length === 2)
                            country = '<img class="country" src="https://www.skipperblogs.com/assets/img/country-flags/24x24/'+extensions.country_code+'.png" title="'+(typeof extensions.country_name !== 'undefined'?extensions.country_name:'')+'">';

                        if(typeof extensions.date !== 'undefined')
                            content += '<br><small class="date">'+country+extensions.date+'</small>';

                        content += '</strong>';
                        content += '</div><div class="tooltip-body">';

                        if(typeof extensions.excerpt !== 'undefined')
                            content += '<p style="margin-bottom: 0">'+extensions.excerpt+'</p>'

                        if(typeof extensions.url !== 'undefined')
                            content += '<a data-id="'+extensions.id+'" style="float: right" href="'+extensions.url+'">'+sblang.discover+'</a><br><br>'



                        content += '</div>';

                    }

                if (!symIcon) {
                    console.log(
                        'No waypoint icon could be matched for symKey=%s,typeKey=%s,name=%s on waypoint %o',
                        symKey, typeKey, name, el[i]);
                    continue;
                }

                if(!options.load_markers)
                    continue;

                var img = 'https://www.skipperblogs.com/assets/img/map-icons/map-empty-marker-img.svg';
                if(typeof extensions.cover_img !== 'undefined')
                    if(extensions.cover_img !== null && extensions.cover_img !== ''){
                        img = extensions.cover_img;
                    }

                var symIcon = L.divIcon(
                    {
                        html: '<img class="marker-img" src="'+img+'">',
                        iconSize:     [40, 40], // size of the icon
                        iconAnchor:   [20, 20], // point of the icon which will correspond to marker's location
                        popupAnchor:  [0, -20], // point from which the popup should open relative to the iconAnchor
                        'className': 'marker-type-'+extensions.point_type
                    }
                );

                var marker = new L.Marker(ll, {
                    clickable: options.marker_options.clickable,
                    title: name,
                    icon: symIcon,
                    type: 'waypoint',
                    extensions: extensions
                });

                var popup = L.responsivePopup(
                    {closeButton:false, offset: new L.Point(-20,20),
                    className: 'popup_item_located '+popupType+'_'+extensions.point_type+(typeof extensions.cover_img === 'undefined' || extensions.cover_img===null || extensions.cover_img===''?' no-img':'')
                    }).setContent(content);
                marker.addTo(map).bindPopup(popup);

                marker.on('mouseover', function (e) {

                    this.openPopup();
                });

                if(options.load_articles ){
                    this.fire('addpoint', { point: marker, point_type: 'article', element: el[i] });
                    layers.push(marker);
                }

                 */
            }
        }

        if (layers.length > 1) {
            return new L.FeatureGroup(layers);
        } else if (layers.length == 1) {
            return layers[0];
        }
    },

    _parse_segment: function(line, options, polyline_options, tag,track_options) {


        var el = line.getElementsByTagName(tag);

        if (!el.length){ // no coordinates in this track.

            var opts = this._extract_styling(line, polyline_options, options.polyline_options);
            opts.track_id = track_options.track_id;
            opts.track_type = track_options.track_type;
            opts.track_name = track_options.track_name;
            opts.track_coords = null;
            opts.noClip = false; // IMPORTANT ! if not set, Leaflet arrow heads will display too much arrows and crash the browser at lower zooms
          
            if(options.display_arrows){
                var polyline1 = new L.Polyline([], opts).arrowheads(arrowHeadsOptions);
                polyline1.options.noClip = false; // IMPORTANT, must be set AFTER polyline creation ! if not set, Leaflet arrow heads will display too much arrows and crash the browser at lower zooms
            }
            else
                var polyline1 = new L.Polyline([], opts);

            this.fire('empty-line', { line: polyline1, element: line, track_type:track_options.track_type, track_id:track_options.track_id  }); // Used in map-editor
            return [];
        }
        var map = options.map;
        var coords = [];
        var markers = [];
        var layers = [];
        var last = null;
        var track_length = 0;
        var avgs_speeds = [];
        var avgs_speeds_24h = [];
        var last_wp = el[0];

        for (var i = 0; i < el.length; i++) {


            var _, ll = new L.LatLng(
                el[i].getAttribute('lat'),
                el[i].getAttribute('lon'));
            ll.meta = { time: null, ele: null, hr: null, cad: null, atemp: null, speed: null };
            
            _ = el[i].getElementsByTagName('time');
            if (_.length > 0) {
                ll.meta.time = new Date(Date.parse(_[0].textContent));
                //only for track tracking
                if(track_options.track_type === 'tracking'){
                    this._info.last_update = ll.meta.time.ddmmyyyyhhmm();
                    this._info.last_updateISO = ll.meta.time.toISOString();
                    this._info.last_updateOBJ = ll.meta.time;
                }


            } else {
                ll.meta.time = new Date('1970-01-01T00:00:00');
            }
            var time_diff = last != null ? Math.abs(ll.meta.time - last.meta.time) : 0; // in milisec
            var yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));


            // calculating the average distance sailed per day, distance in meters
            var distance_from_last_wp = this._dist2d({lat:last_wp.getAttribute('lat'),lng:last_wp.getAttribute('lon')},{lat:el[i].getAttribute('lat'),lng:el[i].getAttribute('lon')});// distance in meters

            // we filter values: We dont take small distances and only waypoint that are less than 24h appart
            // then we take only speeds that make sens
            if(distance_from_last_wp > 10000 && time_diff < 86400000){
                var avg_speed = (distance_from_last_wp / 1852) / (time_diff/1000/3600); // in NM per hour
                if(avg_speed > 1 && avg_speed < 22)
                    avgs_speeds.push(avg_speed);
                try {
                    // If the WP is less than 24h, we update the corresponding average
                    if(ll.meta.time.getTime() > yesterday.getTime()  && avg_speed < 22){
                        avgs_speeds_24h.push(avg_speed);
                    }
                }catch (e) {

                }

            }
            last_wp = el[i]; //next loop


            /*_ = el[i].getElementsByTagName('ele');
            if (_.length > 0) {
                ll.meta.ele = parseFloat(_[0].textContent);
            } else {
                // If the point doesn't have an <ele> tag, assume it has the same
                // elevation as the point before it (if it had one).
                ll.meta.ele = last != null ? last.meta.ele : null;
            }*/
            var ele_diff = last != null ? ll.meta.ele - last.meta.ele : 0;
            var dist_3d = last != null ? this._dist3d(last, ll) : 0;
            track_length += dist_3d;
            _ = el[i].getElementsByTagName('speed');
            if (_.length > 0) {
                ll.meta.speed = parseFloat(_[0].textContent);
            } else {
                // speed in meter per second
                ll.meta.speed = time_diff > 0 ? 1000.0 * dist_3d / time_diff : 0;
            }

            _ = el[i].getElementsByTagName('name');
            if (_.length > 0) {
                var name = _[0].textContent;
                var ptMatchers = options.marker_options.pointMatchers || [];

                for (var j = 0; j < ptMatchers.length; j++) {
                    if (ptMatchers[j].regex.test(name)) {
                        markers.push({ label: name, coords: ll, icon: ptMatchers[j].icon, element: el[i] });
                        break;
                    }
                }
            }

            if(el[i].getElementsByTagName('gpxtpx:speed').length > 0)
                speed = el[i].getElementsByTagName('gpxtpx:speed')[0].textContent;


            // getting the top speed
            if(track_options.include_in_stats && speed > this._info.max_speed)
                this._info.max_speed = speed;


            /// We save all the gpx unsuported data to the javascript object
            var text = null;
            var course = null;
            var speed = null;
            var source = null;
            var time = null;

            if(el[i].getElementsByTagName('text').length > 0)
                text = el[i].getElementsByTagName('text')[0].textContent;
            if(el[i].getElementsByTagName('gpxtpx:course').length > 0)
                course = el[i].getElementsByTagName('gpxtpx:course')[0].textContent;

            if(el[i].getElementsByTagName('source').length > 0)
                source = el[i].getElementsByTagName('source')[0].textContent;
            if(el[i].getElementsByTagName('time').length > 0)
                time = el[i].getElementsByTagName('time')[0].textContent;

            /// We save all the gpx unsuported data to the javascript object
            ll.meta.speed = speed;
            ll.meta.course = course;
            ll.meta.text = text;
            ll.meta.extensions = {};

            try {
                if(el[i].getElementsByTagName('extensions').length > 0){
                    for(var n = 0; n <= el[i].getElementsByTagName('extensions')[0].childNodes.length;n++){
                        if(typeof el[i].getElementsByTagName('extensions')[0].childNodes[n] === 'undefined')
                            continue;

                        var key = el[i].getElementsByTagName('extensions')[0].childNodes[n].nodeName;
                        var value = el[i].getElementsByTagName('extensions')[0].childNodes[n].innerHTML;

                        if(typeof value !== 'undefined' && key !== 'gpxtpx:TrackPointExtension')
                            ll.meta.extensions[key] = value;
                    }
                    function changeTimezone(date, ianatz) {

                        // suppose the date is 12:00 UTC
                        var invdate = new Date(date.toLocaleString('en-US', {
                            timeZone: ianatz
                        }));

                        // then invdate will be 07:00 in Toronto
                        // and the diff is 5 hours
                        var diff = date.getTime() - invdate.getTime();

                        // so 12:00 in Toronto is 17:00 UTC
                        return new Date(date.getTime() - diff); // needs to substract

                    }
                    ll.meta.extensions['time'] = time;
                    ll.meta.extensions['datetime_browser'] = new Date(time);
                    ll.meta.extensions['datetime_utc'] = changeTimezone(new Date(time), "UTC");
                }
            }catch (e) {
                if(typeof reportJSError !== 'undefined')
                    reportJSError(e, 'leaflet-gpx.js', e.lineNumber, null, e);
            }

            //only add info popup on tracking track
            if(track_options.track_type==='tracking'){

                // we don't add a waypoint if it's the last of the segment, the boat icon and text/course/speed will be added to boat icon
                if(i !== el.length - 1 && text !== null){

                    var marker = new L.Marker([el[i].getAttribute('lat'),el[i].getAttribute('lon')], {
                        clickable: options.marker_options.clickable,
                        icon: L.divIcon({
                            html:   '<i class="fa fa-comment">',
                            iconSize:     [25, 25], // size of the icon
                            iconAnchor:   [12, 12], // point of the icon which will correspond to marker's location
                            popupAnchor:  [2, -20], // point from which the popup should open relative to the iconAnchor
                            className: 'waypoint-marker'
                        })
                    }).on('mouseover', function (e) {
                        this.openPopup();
                    });

                    var ext = el[i];
                    var content = '<div class="tooltip-top"><span class="source">';

                    var source = null;
                    var sourceTrans = null;
                    if(ext.getElementsByTagName('source').length > 0){
                        var source = ext.getElementsByTagName('source')[0].textContent;
                        if(source.indexOf('s_') > -1) // translated version of source
                            sourceTrans = sblang[source];
                    }

                    if(typeof trackingSources !== 'undefined' && typeof trackingSources[source] !== 'undefined')
                        content += '<img height="20" style="margin-bottom: -7px" src="'+trackingSources[source]+'">';
                    else
                        content += '<i class="fa fa-location-arrow"></i> ';



                    if(sourceTrans && sourceTrans.length > 1)
                    content += sourceTrans;

                    content += '</span>';
                    content += '</div><div class="tooltip-body"><p>';


                    if(ext.getElementsByTagName('time').length > 0){
                        var date = new Date(time);
                        content += date.ddmmyyyyhhmm()+'<br>';
                    }
                    content += deg_to_dms(el[i].getAttribute('lat'),'lat')+' '+deg_to_dms(el[i].getAttribute('lon'),'lng')+'<br>';
                    if(ext.getElementsByTagName('country_code').length > 0 && ext.getElementsByTagName('country_code')[0].textContent.length === 2){
                        content += '<span class="country"><img width="24" height="24" src="https://www.skipperblogs.com/assets/img/country-flags/24x24/'+ext.getElementsByTagName('country_code')[0].textContent+'.png">'+ext.getElementsByTagName('country_name')[0].textContent+'</span><br>';
                    }

                    if(ext.getElementsByTagName('course').length > 0 && ext.getElementsByTagName('course')[0].textContent.length > 0)
                        content += '<br>'+sblang.course+' '+ext.getElementsByTagName('course')[0].textContent+'°';
                    if(ext.getElementsByTagName('speed').length > 0 && ext.getElementsByTagName('speed')[0].textContent.length > 0){
                        content += '<br>'+sblang.speed+' '+ext.getElementsByTagName('speed')[0].textContent+'kts<br>';
                    }

                    content += '</p>';
                    if(ext.getElementsByTagName('weathercode').length > 0){
                        content += '<span class="weather">';
                        if(ext.getElementsByTagName('period').length > 0 && ext.getElementsByTagName('period')[0].textContent === 'night')
                            content += '<span class="code"><img  width="90" height="70" src="https://www.skipperblogs.com/assets/img/weather-icons/codes/'+ext.getElementsByTagName('weathercode')[0].textContent+'_night.svg"></span>';
                        else
                            content += '<span class="code"><img  width="90" height="70" src="https://www.skipperblogs.com/assets/img/weather-icons/codes/'+ext.getElementsByTagName('weathercode')[0].textContent+'.svg"></span>';

                        if(ext.getElementsByTagName('pressure').length > 0)
                            content += '<i><img width="30" height="30" src="https://www.skipperblogs.com/assets/img/weather-icons/monochrome/raw/wi_barometer_white.svg"> '+ext.getElementsByTagName('pressure')[0].textContent+' hPa</i>';
                        if(ext.getElementsByTagName('temperature').length > 0)
                            content += '<i><img width="30" height="30" src="https://www.skipperblogs.com/assets/img/weather-icons/monochrome/raw/wi_thermometer-celsius_white.svg"> '+ext.getElementsByTagName('temperature')[0].textContent+'°C</i>';
                        if(ext.getElementsByTagName('windspeed').length > 0 && ext.getElementsByTagName('winddirection').length > 0)
                            content += '<i><img width="30" height="30" src="https://www.skipperblogs.com/assets/img/weather-icons/monochrome/raw/wi_windsock_white.svg"> '+ext.getElementsByTagName('windspeed')[0].textContent+' '+sblang.speed_unit+' '+ext.getElementsByTagName('winddirection')[0].textContent+'</i>';

                        content += '</span>';


                    }

                    if(ext.getElementsByTagName('data').length > 0)
                        content += '<div class="data">'+ext.getElementsByTagName('data')[0].textContent+'</div>';
                    if(ext.getElementsByTagName('text').length > 0)
                        content += '<div class="comment"><p><i class="fa fa-comment"></i> '+ext.getElementsByTagName('text')[0].textContent+'</p></div>';

                    content += '</div>';

                    //marker.bindPopup(content,{className:'popup_waypoint_text'});
                    var popup = L.responsivePopup({className: 'popup_waypoint_text', closeButton:false, offset: new L.Point(-20,20)}).setContent(content);
                    marker.addTo(map).bindPopup(popup);

                    marker.on('mouseover', function (e) {

                        this.openPopup();
                    });

                    // we only add a marker if it contains some text
                    if(ext.getElementsByTagName('text').length > 0){
                        this.fire('addpoint', { point: marker, point_type: 'waypoint', element: el[el.length-1] });
                        layers.push(marker);
                    }

                }
            }

            if(track_options.include_in_stats){


                _ = el[i].getElementsByTagNameNS('*', 'hr');
                if (_.length > 0) {
                    ll.meta.hr = parseInt(_[0].textContent);
                    this._info.hr._points.push([this._info.length, ll.meta.hr]);
                    this._info.hr._total += ll.meta.hr;
                }

                _ = el[i].getElementsByTagNameNS('*', 'cad');
                if (_.length > 0) {
                    ll.meta.cad = parseInt(_[0].textContent);
                    this._info.cad._points.push([this._info.length, ll.meta.cad]);
                    this._info.cad._total += ll.meta.cad;
                }

                _ = el[i].getElementsByTagNameNS('*', 'atemp');
                if (_.length > 0) {
                    ll.meta.atemp = parseInt(_[0].textContent);
                    this._info.atemp._points.push([this._info.length, ll.meta.atemp]);
                    this._info.atemp._total += ll.meta.atemp;
                }

                if (ll.meta.ele > this._info.elevation.max) {
                    this._info.elevation.max = ll.meta.ele;
                }
                if (ll.meta.ele < this._info.elevation.min) {
                    this._info.elevation.min = ll.meta.ele;
                }
                this._info.elevation._points.push([this._info.length, ll.meta.ele]);

                if (ll.meta.speed > this._info.speed.max) {
                    this._info.speed.max = ll.meta.speed;
                }
                this._info.speed._points.push([this._info.length, ll.meta.speed]);

                if ((last == null) && (this._info.duration.start == null)) {
                    this._info.duration.start = ll.meta.time;
                }
                this._info.duration.end = ll.meta.time;
                this._info.duration.total += time_diff;
                if (time_diff < options.max_point_interval) {
                    this._info.duration.moving += time_diff;
                }

                this._info.length += dist_3d;

                if (ele_diff > 0) {
                    this._info.elevation.gain += ele_diff;
                } else {
                    this._info.elevation.loss += Math.abs(ele_diff);
                }
            }
            last = ll;
            coords.push(ll);
        }

        // updating/calculating average speed
        if(avgs_speeds.length > 0){
            var total = 0;

            for (i = 0; i < avgs_speeds.length; i++)
                total = total + avgs_speeds[i];

            avg_speed_imp = (total / avgs_speeds.length);
        }
        // updating/calculating average speed
        if(avgs_speeds_24h.length > 0){
            var total = 0;

            for (i = 0; i < avgs_speeds_24h.length; i++)
                total = total + avgs_speeds_24h[i];

            avg_speed_24_imp = (total / avgs_speeds_24h.length);
        }

        // add track
        var opts = this._extract_styling(line, polyline_options, options.polyline_options);

        // Manage WP crossing the dateline
        var prev_coords = coords[0];
        var date_line_passed_to_west = false;
        var date_line_passed_to_east = false;

/*
        for (var k=0 ; k < coords.length ; k++){



            if( prev_coords.lng < 0 && coords[k].lng > 0 && Math.abs(coords[k].lng) > 100){ // if coords changed from -180 to +180° // and we check if it's not the Greenwich meridian


                date_line_passed_to_west = true;
                date_line_passed_to_east = false;
            }
            else if(prev_coords.lng > 0 && coords[k].lng < 0 && Math.abs(coords[k].lng) > 100) {// if coords changed from +180 to -180° // and we check if it's not the Greenwich meridian

                date_line_passed_to_east = true;
                date_line_passed_to_west = false;

            }

            prev_coords = {lat:coords[k].lat,lng:coords[k].lng}; // save original coords

            //// WE HAVE TO CUT POLYLINE AT THE DATE LINE EDGES !!!


         //   if(date_line_passed_to_west)
         //       coords[k].lng = coords[k].lng - 360;
         //   else if(date_line_passed_to_east)
         //       coords[k].lng = coords[k].lng + 360;



        }
 */
        opts.track_id = track_options.track_id;
        opts.track_type = track_options.track_type;
        opts.track_name = track_options.track_name;

        coords = anteMeridian(coords);

        // opts.track_coords = coords; used ??

        if(options.display_arrows) {
            var l = new L.Polyline(coords, opts).arrowheads(arrowHeadsOptions);
            l.options.noClip = false; // IMPORTANT, must be set AFTER polyline creation ! if not set, Leaflet arrow heads will display too much arrows and crash the browser at lower zooms
        }
        else
            var l = new L.Polyline(coords, opts);


        // After creating the polyline, we have to put back metadata
        try {
            for (var i = 0; i < l.getLatLngs().length; i++) {
                if(l.getLatLngs()[i].lat === coords[i].lat && l.getLatLngs()[i].lng === coords[i].lng)
                    l.getLatLngs()[i].meta = coords[i].meta;
            }
        } catch (e) {
            reportJSError(e.message,'leaflet-gpx.js','l.getLatLngs().forEach','0','Error');
        }

        setTimeout(function () {
            l.bringToBack(); // we want the circlemarker over the track after all instanciation
        },1000);
        //l.bindTooltip('Distance totale <strong>'+parseInt(this.to_miles(this.m_to_km(track_length)))+' NM</strong> ( '+parseInt(this.m_to_km(track_length))+' kms)');

       // l = handleAnteMeridian(l);

        if(!options.load_only_tracking || (options.load_only_tracking && track_options.track_type === 'tracking')){
            this.fire('addline', { line: l, element: line, track_type:track_options.track_type, track_id:track_options.track_id  });

            layers.push(l);
        }

        if(coords.length > 1) //dont display start marker if track has only one point
            if (options.marker_options.startIcon || options.marker_options.startIconUrl && track_options.track_type === 'tracking') {
                // add start pin
                var marker = new L.Marker(coords[0], {
                    clickable: options.marker_options.clickable,
                    icon: options.marker_options.startIcon || new L.GPXTrackIcon({
                        iconUrl: options.marker_options.startIconUrl,
                        className: 'start-marker',
                        iconAnchor:   [6, 44]
                    }),
                });
                var content = '<div class="tooltip-body"><strong class="title">'+sblang.track_departure+'<br><small class="date">'+formatDate(this._info.duration.start)+'</small></strong></div><div class="tooltip-body"><p style="margin-bottom: 0"></p></div>';

                var popup = L.responsivePopup(
                    {closeButton:false, offset: new L.Point(-40,20),
                        className: 'popup_departure popup_no-img'
                    }).setContent(content);

                marker.addTo(map).bindPopup(popup).on('mouseover', function (e) {
                    this.openPopup();
                });

                this.fire('addpoint', { point: marker, track_type:track_options.track_type, point_type: 'start', element: el[0] });
                layers.push(marker);
            }

        if(track_options.track_type === 'tracking' && track_options.boat_icon !== null){

            // turning the boat icon with the track orientation but only if we have more than 1 point
            var heading_west = true;
            if(typeof coords[coords.length-2] !== 'undefined'){
                var lng1 = coords[coords.length-1].lng;
                var lng2 = coords[coords.length-2].lng;
                heading_west = lng1 < lng2;
            }

            var icon = track_options.boat_icon;

            if(heading_west){
                var img = track_options.boat_icon.split('.');
                icon = img[0]+'-reverse.'+img[1];
                track_options.boat_icon
            }

            // add boat pin
            var marker = new L.Marker(coords[coords.length-1], {
                clickable: options.marker_options.clickable,
                icon: L.icon({
                    iconUrl:    'https://www.skipperblogs.com'+icon,
                    iconSize:     [40, 40], // size of the icon
                    iconAnchor:   [20, 20], // point of the icon which will correspond to marker's location
                    popupAnchor:  [2, -20], // point from which the popup should open relative to the iconAnchor
                    className: 'boat-marker'
                })
            });

            //this._info.last_pos = deg_to_dms(marker._latlng.lat,'lat')+' '+deg_to_dms(marker._latlng.lng,'lng');
            this._info.last_pos = deg_to_dms(coords[coords.length-1].lat,'lat')+' '+deg_to_dms(coords[coords.length-1].lng,'lng');
            this._info.boat_marker = marker;
            this._info.track_tracking = l;

            var ext = el[el.length-1];
            var content = '<div class="tooltip-top"><span class="source"> ';

            if(ext.getElementsByTagName('source').length > 0){

                var source = ext.getElementsByTagName('source')[0].textContent;

                if(typeof trackingSources !== 'undefined' && typeof trackingSources[source] !== 'undefined')
                    content += '<img height="20" style="margin-bottom: -7px" src="'+trackingSources[source]+'"> ';
                else
                    content += '<i class="fa fa-location-arrow"></i> ';


                if(source.indexOf('s_') > -1) // translated version of source
                    source = sblang[source];
                content += source;
            }

            content += '</span>';


            content += '</div><div class="tooltip-body"><p>';
            content += this._info.last_update+'<br>'+this._info.last_pos;

            if(ext.getElementsByTagName('country_code').length > 0 && ext.getElementsByTagName('country_code')[0].textContent.length === 2)
                content += '<br>'+'<span class="country"><img  src="https://www.skipperblogs.com/assets/img/country-flags/24x24/'+ext.getElementsByTagName('country_code')[0].textContent+'.png">'+ext.getElementsByTagName('country_name')[0].textContent+'</span>';

            if(ext.getElementsByTagName('course').length > 0 && ext.getElementsByTagName('course')[0].textContent.length > 0)
                content += '<br>'+sblang.course+' '+ext.getElementsByTagName('course')[0].textContent+'°';
            if(ext.getElementsByTagName('speed').length > 0 && ext.getElementsByTagName('speed')[0].textContent.length > 0)
                content += '<br>'+sblang.speed+' '+ext.getElementsByTagName('speed')[0].textContent+'kts<br>';

            content += '</p>';
            if(ext.getElementsByTagName('weathercode').length > 0){
                content += '<span class="weather">';
                if(ext.getElementsByTagName('period').length > 0 && ext.getElementsByTagName('period')[0].textContent === 'night')
                    content += '<span class="code"><img  width="90" height="70" src="https://www.skipperblogs.com/assets/img/weather-icons/codes/'+ext.getElementsByTagName('weathercode')[0].textContent+'_night.svg"></span>';
                else
                    content += '<span class="code"><img  width="90" height="70" src="https://www.skipperblogs.com/assets/img/weather-icons/codes/'+ext.getElementsByTagName('weathercode')[0].textContent+'.svg"></span>';

                if(ext.getElementsByTagName('pressure').length > 0)
                    content += '<i><img width="30" height="30" src="https://www.skipperblogs.com/assets/img/weather-icons/monochrome/raw/wi_barometer_white.svg"> '+ext.getElementsByTagName('pressure')[0].textContent+' hPa</i>';
                if(ext.getElementsByTagName('temperature').length > 0)
                    content += '<i><img width="30" height="30" src="https://www.skipperblogs.com/assets/img/weather-icons/monochrome/raw/wi_thermometer-celsius_white.svg"> '+ext.getElementsByTagName('temperature')[0].textContent+'°C</i>';
                if(ext.getElementsByTagName('windspeed').length > 0 && ext.getElementsByTagName('winddirection').length > 0)
                    content += '<i><img width="30" height="30" src="https://www.skipperblogs.com/assets/img/weather-icons/monochrome/raw/wi_windsock_white.svg"> '+ext.getElementsByTagName('windspeed')[0].textContent+sblang.speed_unit+' '+ext.getElementsByTagName('winddirection')[0].textContent+'</i>';

                content += '</span>';


            }

            if(ext.getElementsByTagName('data').length > 0)
                content += '<div class="data">'+ext.getElementsByTagName('data')[0].textContent+'</div>';
            if(ext.getElementsByTagName('text').length > 0)
                content += '<div class="comment"><p>'+ext.getElementsByTagName('text')[0].textContent+'</p></div>';

            content += '</div>';


            //marker.bindTooltip(content);
            var popup = L.responsivePopup({className: 'popup_waypoint_text', closeButton:false, offset: new L.Point(-20,20)}).setContent(content);
            marker.addTo(map).bindPopup(popup);
            marker.on('mouseover', function (e) {
                this.openPopup();
            }).on('mouseout', function () {
                this.closePopup();
            });

            this.fire('addpoint', { point: marker, track_type:track_options.track_type, point_type: 'end', element: el[el.length-1] });
            layers.push(marker);
        }
        else if (options.marker_options.endIcon || options.marker_options.endIconUrl && track_options.track_type === 'tracking') {
            // add end pin
            var marker = new L.Marker(coords[coords.length-1], {
                clickable: options.marker_options.clickable,
                icon: options.marker_options.endIcon || new L.GPXTrackIcon({iconUrl: options.marker_options.endIconUrl})
            });
            this.fire('addpoint', { point: marker, point_type: 'end', track_type:track_options.track_type, element: el[el.length-1] });
            layers.push(marker);
        }

        // add named markers

        if(options.load_markers)
        for (var i = 0; i < markers.length; i++) {
            var marker = new L.Marker(markers[i].coords, {
                clickable: options.marker_options.clickable,
                title: markers[i].label,
                icon: markers[i].icon
            });
            this.fire('addpoint', { point: marker, point_type: 'label', element: markers[i].element });
            layers.push(marker);
        }

        return layers;
    },

    _extract_styling: function(el, base, overrides) {
        var style = this._merge_objs(_DEFAULT_POLYLINE_OPTS, base);
        var e = el.getElementsByTagNameNS(_GPX_STYLE_NS, 'line');
        if (e.length > 0) {
            var _ = e[0].getElementsByTagName('color');
            if (_.length > 0) style.color = '#' + _[0].textContent;
            var _ = e[0].getElementsByTagName('opacity');
            if (_.length > 0) style.opacity = _[0].textContent;
            var _ = e[0].getElementsByTagName('weight');
            if (_.length > 0) style.weight = _[0].textContent;
            var _ = e[0].getElementsByTagName('linecap');
            if (_.length > 0) style.lineCap = _[0].textContent;
            var _ = e[0].getElementsByTagName('linejoin');
            if (_.length > 0) style.lineJoin = _[0].textContent;
            var _ = e[0].getElementsByTagName('dasharray');
            if (_.length > 0) style.dashArray = _[0].textContent;
            var _ = e[0].getElementsByTagName('dashoffset');
            if (_.length > 0) style.dashOffset = _[0].textContent;
            var _ = e[0].getElementsByTagName('hidden');
            if (_.length > 0) style.hidden = !!+_[0].textContent; // convert 1/0 to bool
            var _ = e[0].getElementsByTagName('include_in_stats');
            if (_.length > 0) style.include_in_stats = !!+_[0].textContent;// convert 1/0 to bool
        }
        return this._merge_objs(style, overrides)
    },

    _dist2d: function(a, b) {
        var R = 6371000;
        var dLat = this._deg2rad(b.lat - a.lat);
        var dLon = this._deg2rad(b.lng - a.lng);
        var r = Math.sin(dLat/2) *
            Math.sin(dLat/2) +
            Math.cos(this._deg2rad(a.lat)) *
            Math.cos(this._deg2rad(b.lat)) *
            Math.sin(dLon/2) *
            Math.sin(dLon/2);
        var c = 2 * Math.atan2(Math.sqrt(r), Math.sqrt(1-r));
        var d = R * c;
        return d;
    },

    _dist3d: function(a, b) {
        var planar = this._dist2d(a, b);
        var height = Math.abs(b.meta.ele - a.meta.ele);
        return Math.sqrt(Math.pow(planar, 2) + Math.pow(height, 2));
    },

    _deg2rad: function(deg) {
        return deg * Math.PI / 180;
    }
});

if (typeof module === 'object' && typeof module.exports === 'object') {
    module.exports = L;
} else if (typeof define === 'function' && define.amd) {
    define(L);
}
